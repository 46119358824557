import React, { FC } from 'react'
import ProfileTopSection from '../ProfileTopSection'
import { ProfileViewSectionInterface } from './interface'

const ProfileViewSection: FC<ProfileViewSectionInterface> = ({
  setEditProfileState,
  profileInfoNoPendingData,
}) => {
  const handleClick = () => {
    setEditProfileState(true)
  }
  return (
    <>
      <div className='profile'>
        <ProfileTopSection
          title='Profile'
          description='The limit for changing your info is 3 times each month and 9 times every 6 months.'
          type='view'
          buttonTitle='Edit'
          onClick={handleClick}
        />
        {profileInfoNoPendingData && (
          <div className='profile-info-box'>
            <div className='colum'>
              <div>
                <label>First Name</label>
                <p>{profileInfoNoPendingData?.data?.firstName}</p>
              </div>
              <div>
                <label>Last Name</label>
                <p>{profileInfoNoPendingData?.data?.lastName}</p>
              </div>
              <div>
                <label>Email:</label>
                <p>{profileInfoNoPendingData?.data?.email}</p>
              </div>
            </div>
            <div className='colum'>
              <div>
                <label>Birth Date</label>
                <p>{profileInfoNoPendingData?.data.birthDate}</p>
              </div>
              <div>
                <label>City</label>
                <p>{profileInfoNoPendingData?.data?.city}</p>
              </div>
              <div>
                <label>State</label>
                <p>{profileInfoNoPendingData?.data?.state}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ProfileViewSection
