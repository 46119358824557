import React from 'react'
import SignUp from './components/Login/SignUp/index'
import PageNotFound from './components/404/index'
import SignIn from './components/Login/SignIn/index'
import ForgotPassword from './components/ForgotPassword/index'
import Home from './components/Home/index'
import Result from './components/Results/index'
import Subscribe from './components/SubscribePlan/index'
import Profile from './components/Profile/ProfileFields/index'
import ContactUs from './components/ContactUs/index'
import PrivacyPolice from './components/PrivacyPolicy/index'
import Terms from './components/Terms/index'
import ProfileView from './components/Profile/ProfileSetting/ProfileView/index'
import ProfilePlan from './components/Profile/ProfileSetting/ProfilePlan'
import ProfileSecurity from './components/Profile/ProfileSetting/ProfileSecurity'
import SearchResults from './components/Profile/SearchResults'

export const router = [
  {
    path: '*',
    element: <PageNotFound />,
  },
  {
    path: '/',
    element: <Home />,
    route: 'auth',
  },
  {
    path: '/sign-up',
    element: <SignUp />,
    route: 'auth',
  },
  {
    path: '/sign-in',
    element: <SignIn />,
    route: 'auth',
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    route: 'auth',
  },
  {
    path: '/result',
    element: <Result />,
    route: '',
  },
  {
    path: '/subscribe',
    element: <Subscribe />,
    route: '',
  },
  {
    path: '/profile',
    element: <Profile />,
    route: '',
  },
  {
    path: '/contact',
    element: <ContactUs />,
    route: '',
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolice />,
    route: '',
  },
  {
    path: '/terms',
    element: <Terms />,
    route: '',
  },
  {
    path: '/profile-view',
    element: <ProfileView />,
    route: '',
  },
  {
    path: '/profile-plan',
    element: <ProfilePlan />,
    route: 'private',
  },
  {
    path: '/profile-security',
    element: <ProfileSecurity />,
    route: 'private',
  },
  {
    path: '/search-result',
    element: <SearchResults />,
    route: 'private',
  },
]
