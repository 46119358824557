import { HTTP } from '../../utils/http'
import { CONTACT } from '../constants'
import { ContactBody } from './interfaces'

export const ContactAction = (body: ContactBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: CONTACT.PENDING })
    const response = await HTTP.post('api/support/contact-us', body)
    dispatch({ type: CONTACT.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: CONTACT.ERROR, error: error })
  }
}

export const ContactReset = () => ({
  type: CONTACT.RESET,
})
