import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { router } from './routes'
import GlobalRoute from './components/GlobalRoute/index'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey='6LfWmjApAAAAAOm1bpI01HBXe1dBrhgXnE5GUUPD'>
      <BrowserRouter>
        <Routes>
          {router.map(({ path, element, route }) => (
            <Route
              key={path}
              path={path}
              element={route ? <GlobalRoute route={route}>{element}</GlobalRoute> : element}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  )
}

export default App
