import {
  PROFILE,
  PROFILE_INFO_NO_PENDING,
  PROFILE_RESULTS_NO_PENDING,
  PROFILE_EDIT,
  DELETE_ACCOUNT,
  CANCEL_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
} from '../constants'
import { DispatchPayloadType } from './index'

import { ProfileReducersInterface } from './interfaces'

const initialState: ProfileReducersInterface = {
  profileStatus: '',
  profileData: null,

  profileInfoNoPendingStatus: '',
  profileInfoNoPendingData: null,

  profileResultNoPendingStatus: '',
  profileResultNoPendingData: null,

  profileEditStatus: '',
  profileEditData: null,

  accountDeleteStatus: '',
  accountDeleteData: null,

  cancelSubscriptionStatus: '',
  cancelSubscriptionData: null,

  resumeSubscriptionStatus: '',
  resumeSubscriptionData: null,
}

const profileReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): ProfileReducersInterface => {
  switch (type) {
    case PROFILE.PENDING: {
      return {
        ...state,
        profileStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE.SUCCESS: {
      return {
        ...state,
        profileStatus: 'success',
        profileData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE.ERROR: {
      return {
        ...state,
        profileStatus: 'error',
        profileData: error.response.data,
      } as ProfileReducersInterface
    }
    case PROFILE.RESET: {
      return {
        ...state,
        profileStatus: '',
      } as ProfileReducersInterface
    }

    case PROFILE_INFO_NO_PENDING.PENDING: {
      return {
        ...state,
        profileInfoNoPendingStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE_INFO_NO_PENDING.SUCCESS: {
      return {
        ...state,
        profileInfoNoPendingStatus: 'success',
        profileInfoNoPendingData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE_INFO_NO_PENDING.ERROR: {
      return {
        ...state,
        profileInfoNoPendingStatus: 'error',
        profileInfoNoPendingData: error.response?.data,
      } as ProfileReducersInterface
    }
    case PROFILE_INFO_NO_PENDING.RESET: {
      return {
        ...state,
        profileStatus: '',
      } as ProfileReducersInterface
    }

    case PROFILE_RESULTS_NO_PENDING.PENDING: {
      return {
        ...state,
        profileResultNoPendingStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE_RESULTS_NO_PENDING.SUCCESS: {
      return {
        ...state,
        profileResultNoPendingStatus: 'success',
        profileResultNoPendingData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE_RESULTS_NO_PENDING.ERROR: {
      return {
        ...state,
        profileResultNoPendingStatus: 'error',
        profileResultNoPendingData: error.response?.data,
      } as ProfileReducersInterface
    }
    case PROFILE_RESULTS_NO_PENDING.RESET: {
      return {
        ...state,
        profileResultNoPendingStatus: '',
      } as ProfileReducersInterface
    }

    case PROFILE_EDIT.PENDING: {
      return {
        ...state,
        profileEditStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.SUCCESS: {
      return {
        ...state,
        profileEditStatus: 'success',
        profileEditData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.ERROR: {
      return {
        ...state,
        profileEditStatus: 'error',
        profileEditData: error.response.data,
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.RESET: {
      return {
        ...state,
        profileEditStatus: '',
      } as ProfileReducersInterface
    }

    case DELETE_ACCOUNT.PENDING: {
      return {
        ...state,
        accountDeleteStatus: 'pending',
      } as ProfileReducersInterface
    }
    case DELETE_ACCOUNT.SUCCESS: {
      return {
        ...state,
        accountDeleteStatus: 'success',
        accountDeleteData: payload,
      } as ProfileReducersInterface
    }
    case DELETE_ACCOUNT.ERROR: {
      return {
        ...state,
        accountDeleteStatus: 'error',
        accountDeleteData: error.response.data,
      } as ProfileReducersInterface
    }
    case DELETE_ACCOUNT.RESET: {
      return {
        ...state,
        accountDeleteStatus: '',
      } as ProfileReducersInterface
    }

    case CANCEL_SUBSCRIPTION.PENDING: {
      return {
        ...state,
        cancelSubscriptionStatus: 'pending',
      } as ProfileReducersInterface
    }
    case CANCEL_SUBSCRIPTION.SUCCESS: {
      return {
        ...state,
        cancelSubscriptionStatus: 'success',
        cancelSubscriptionData: payload,
      } as ProfileReducersInterface
    }
    case CANCEL_SUBSCRIPTION.ERROR: {
      return {
        ...state,
        cancelSubscriptionStatus: 'error',
        cancelSubscriptionData: error.response.data,
      } as ProfileReducersInterface
    }
    case CANCEL_SUBSCRIPTION.RESET: {
      return {
        ...state,
        cancelSubscriptionStatus: '',
      } as ProfileReducersInterface
    }

    case RESUME_SUBSCRIPTION.PENDING: {
      return {
        ...state,
        resumeSubscriptionStatus: 'pending',
      } as ProfileReducersInterface
    }
    case RESUME_SUBSCRIPTION.SUCCESS: {
      return {
        ...state,
        resumeSubscriptionStatus: 'success',
        resumeSubscriptionData: payload,
      } as ProfileReducersInterface
    }
    case RESUME_SUBSCRIPTION.ERROR: {
      return {
        ...state,
        resumeSubscriptionStatus: 'error',
        resumeSubscriptionData: error.response.data,
      } as ProfileReducersInterface
    }
    case RESUME_SUBSCRIPTION.RESET: {
      return {
        ...state,
        resumeSubscriptionStatus: '',
        resumeSubscriptionData: null,
      } as ProfileReducersInterface
    }

    default:
      return state
  }
}

export default profileReducer
