import React, { FC, useState, useEffect } from 'react'
import { ResultSectionInterfaces } from './interfaces'
import './styles.scss'
import { useSearchParams, useNavigate } from 'react-router-dom'
import ResultTableIsLogedin from './ResultTableIsLogedin'
import ResultTableLogedin from './ResultTableLogedin'
import Spinner from '../../core-ui/Spinner'

const ResultSection: FC<ResultSectionInterfaces> = ({
  searchStatus,
  // searchAction,
  resultListAction,
  requestId,
  resultListData,
  resultRemoveAction,
  resultRemoveStatus,
  resultListStatus,
}) => {
  const [searchParams] = useSearchParams()
  const login = localStorage.getItem('login')

  // const [selectState, setSelectState] = useState('')
  // const [formData, setFormData] = useState({
  //   firstName: '',
  //   lastName: '',
  //   city: '',
  // })
  const [buttonState, setButtonState] = useState(false)
  const navigate = useNavigate()

  // const handleChange = (event_: React.ChangeEvent<HTMLInputElement>, stateName: string) => {
  //   const { value } = event_.target
  //   setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  // }
  // const handleChangeSelect = (event: string) => {
  //   setSelectState(event)
  // }
  // const { Option } = Select
  useEffect(() => {
    if (searchParams.get('id')) {
      if (!buttonState) {
        const body = {
          // eslint-disable-next-line camelcase
          request_id: searchParams.get('id') || '',
        }
        resultListAction(body)
      } else if (searchStatus === 'success') {
        navigate(`/result?id=${requestId}`)
      }
    }
  }, [buttonState, searchStatus])

  // const handleClick = (e: any) => {
  //   setButtonState(true)
  //   e.preventDefault()
  //   const body = {
  //     // eslint-disable-next-line camelcase
  //     first_name: formData.firstName,
  //     // eslint-disable-next-line camelcase
  //     last_name: formData.lastName,
  //     city: formData.city,
  //     state: selectState,
  //   }
  //   searchAction(body)
  // }

  return (
    <>
      <div>
        {/* <form onSubmit={handleClick} className='search-box search-result-section'>
          <div>
            <label>First Name*</label>
            <Input
              name='firstName'
              type='text'
              required
              onChange={(e) => handleChange(e, 'firstName')}
              placeholder='e.g John'
            />
          </div>
          <div>
            <label>Last Name*</label>
            <Input
              name='lastName'
              type='text'
              required
              onChange={(e) => handleChange(e, 'lastName')}
              placeholder='e.g Rogers'
            />
          </div>
          <div>
            <label>City</label>
            <Input name='city' required type='text' onChange={(e) => handleChange(e, 'city')} />
          </div>
          <div>
            <label>State</label>
            <Select onChange={(e) => handleChangeSelect(e)}>
              {state.map(({ name, code }) => (
                <>
                  <Option value={code}>{name}</Option>
                </>
              ))}
            </Select>{' '}
          </div>
          <button type='submit'>{searchStatus === 'pending' ? <Spinner /> : 'Search'}</button>
        </form> */}
        {resultListStatus === 'pending' ? (
          <div className='big-spinner'>
            <Spinner />
          </div>
        ) : (
          <div className='search-result-parent'>
            {login ? (
              <ResultTableLogedin
                resultRemoveStatus={resultRemoveStatus}
                resultRemoveAction={resultRemoveAction}
                resultListData={resultListData}
              />
            ) : (
              <ResultTableIsLogedin
                resultRemoveStatus={resultRemoveStatus}
                resultRemoveAction={resultRemoveAction}
                resultListData={resultListData}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ResultSection
