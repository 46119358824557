import React, { useState, FC, useEffect } from 'react'
import { Input, Select } from 'antd'
import { InformationInternetInterface } from './interfaces'
import Spinner from '../../core-ui/Spinner'
import state from '../../assets/state'
import { useNavigate } from 'react-router-dom'

const { Option } = Select

const InformationInternet: FC<InformationInternetInterface> = ({
  searchAction,
  searchStatus,
  requestId,
  searchResetAction,
}) => {
  const [selectState, setSelectState] = useState('')
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    city: '',
  })
  const navigate = useNavigate()

  const handleChange = (event_: React.ChangeEvent<HTMLInputElement>, stateName: string) => {
    const { value } = event_.target
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }
  const handleChangeSelect = (event: string) => {
    setSelectState(event)
  }

  const handleClick = (e: any) => {
    e.preventDefault()
    const body = {
      // eslint-disable-next-line camelcase
      first_name: formData.firstName,
      // eslint-disable-next-line camelcase
      last_name: formData.lastName,
      city: formData.city,
      state: selectState,
    }
    searchAction(body)
  }
  useEffect(() => {
    if (searchStatus === 'success' && requestId) {
      navigate(
        `/result?id=${requestId}&firstName=${formData.firstName}&lastName=${formData.lastName}&city=${formData.city}&state=${selectState}`,
      )
      searchResetAction()
    }
  }, [searchStatus])

  return (
    <>
      <div className='information-container'>
        <div className='container'>
          <div className='information'>
            <h1>Erase your Sensitive Information from the internet</h1>
            <p>
              InfoVanisher contains billions of pieces of data from millions of national, state, and
              local public records. <br />
              We scour data from specialized sources to give you valuable information in one
              location.
            </p>
            <form onSubmit={handleClick} className='search-box'>
              <div>
                <label>First Name*</label>
                <Input
                  name='firstName'
                  type='text'
                  required
                  onChange={(e) => handleChange(e, 'firstName')}
                  placeholder='e.g John'
                />
              </div>
              <div>
                <label>Last Name*</label>
                <Input
                  name='lastName'
                  type='text'
                  required
                  onChange={(e) => handleChange(e, 'lastName')}
                  placeholder='e.g Rogers'
                />
              </div>
              <div>
                <label>City</label>
                <Input name='city' required type='text' onChange={(e) => handleChange(e, 'city')} />
              </div>
              <div>
                <label>State</label>
                <Select
                  suffixIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='9'
                      viewBox='0 0 16 9'
                      fill='none'
                    >
                      <path
                        d='M0.874023 0.857422L7.5567 7.5401C7.60548 7.59207 7.66439 7.6335 7.72981 7.66182C7.79522 7.69013 7.86574 7.70474 7.93702 7.70474C8.0083 7.70474 8.07882 7.69013 8.14423 7.66182C8.20964 7.6335 8.26856 7.59207 8.31733 7.5401L15 0.857422'
                        stroke='#002661'
                        strokeWidth='1.71429'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  }
                  onChange={(e) => handleChangeSelect(e)}
                >
                  {state.map(({ name, code }) => (
                    <Option key={name} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>{' '}
              </div>
              <button type='submit'>{searchStatus === 'pending' ? <Spinner /> : 'Search'}</button>
            </form>
            <p className='lock'>
              <img src={require('../../assets/images/home/lock.svg').default} /> We respect your
              privacy.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default InformationInternet
