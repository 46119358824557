import React from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

const ProfileNavbar = () => {
  const navigate = useNavigate()
  const handleClick = (val: string) => {
    navigate(`/profile-${val}`)
  }

  return (
    <>
      <div className='profile-navbar'>
        <h1>Settings</h1>
        <div className='menu'>
          <div
            className={
              window.location.pathname === '/profile-view'
                ? 'menu-item menu-item-active'
                : 'menu-item'
            }
            onClick={() => handleClick('view')}
          >
            <img src={require('../../../../assets/images/profile/profile.svg').default} />
            <p>Profile</p>
          </div>
          <div
            className={
              window.location.pathname === '/profile-plan'
                ? 'menu-item menu-item-active'
                : 'menu-item'
            }
            onClick={() => handleClick('plan')}
          >
            <img src={require('../../../../assets/images/profile/billing.svg').default} />
            <p>Plan & Billing</p>
          </div>
          <div
            className={
              window.location.pathname === '/profile-security'
                ? 'menu-item menu-item-active'
                : 'menu-item'
            }
            onClick={() => handleClick('security')}
          >
            <img src={require('../../../../assets/images/profile/security.svg').default} />
            <p>Security</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileNavbar
