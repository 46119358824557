import { HTTP } from '../../utils/http'
import { SUBSCRIBE_PAYMENT, GET_SUBSCRIPTION, SUBSCRIPTION_UPGRATE } from '../constants'
import { SubscribeBody, PaymentSubscriptionUpgrateBody } from './interfaces'

export const Subscibe = (body: SubscribeBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SUBSCRIBE_PAYMENT.PENDING })
    const response = await HTTP.post('api/payment/subscription', body)

    dispatch({ type: SUBSCRIBE_PAYMENT.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: SUBSCRIBE_PAYMENT.ERROR, payload: error })
  }
}

export const GetSubscibe = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: GET_SUBSCRIPTION.PENDING })
    const response = await HTTP.get('api/payment/subscription')

    dispatch({ type: GET_SUBSCRIPTION.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: GET_SUBSCRIPTION.ERROR, payload: error })
  }
}

export const PaymentSubscriptionUpgrate =
  (body: PaymentSubscriptionUpgrateBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: SUBSCRIPTION_UPGRATE.PENDING })
      const response = await HTTP.patch('api/payment/subscription', body)
      dispatch({ type: SUBSCRIPTION_UPGRATE.SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({ type: SUBSCRIPTION_UPGRATE.ERROR, payload: error })
    }
  }

export const SubscibeUpgrateReset = () => ({
  type: SUBSCRIPTION_UPGRATE.RESET,
})

export const SubscibeReset = () => ({
  type: SUBSCRIBE_PAYMENT.RESET,
})
