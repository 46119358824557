import React, { FC, useState, useEffect } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import InnerPage from '../../../InnerPage'
import ProfileNavbar from '../ProfileNavbar'
import ProfileViewSection from './ProfileViewSection'
import ProfileEditSection from '../ProfileEditSection/index'
import {
  ProfileEditInfoAction,
  ProfileInfoNoPendingAction,
} from '../../../../store/actions/ProfileAction'
import { CombineInterface } from '../../../../store/reducers/interfaces'
import { ProfileViewInterface } from './interface'
import Spinner from '../../../../core-ui/Spinner'
import { useNavigate } from 'react-router-dom'
import { ProfileEditBody } from '../../../../store/actions/interfaces'

const ProfileView: FC<ProfileViewInterface> = ({
  profileReducer: {
    profileInfoNoPendingData,
    profileInfoNoPendingStatus,
    profileEditStatus,
    profileEditData,
  },
  profileInfoNoPendingAction,
  profileEditInfoAction,
}) => {
  const [editProfileState, setEditProfileState] = useState(false)
  useEffect(() => {
    profileInfoNoPendingAction()
  }, [])
  const navigate = useNavigate()

  useEffect(() => {
    const payment = localStorage.getItem('payment')
    if (!payment) {
      navigate('/subscribe')
    }
  }, [])

  useEffect(() => {
    if (profileEditStatus === 'success') {
      setEditProfileState(false)
      profileInfoNoPendingAction()
    }
  }, [profileEditStatus])

  useEffect(() => {
    if (
      profileInfoNoPendingData?.data &&
      !Object.values(profileInfoNoPendingData.data).length &&
      profileInfoNoPendingStatus === 'success'
    ) {
      navigate('/profile')
    }
  }, [profileInfoNoPendingData, profileInfoNoPendingStatus])

  return (
    <>
      <InnerPage>
        <div className='profile-section'>
          <div className='container'>
            <div className='profile-section-child'>
              <ProfileNavbar />
              {editProfileState ? (
                <ProfileEditSection
                  profileEditInfoAction={profileEditInfoAction}
                  profileEditStatus={profileEditStatus}
                  profileEditData={profileEditData?.error || ''}
                  profileInfoNoPendingData={profileInfoNoPendingData}
                  setEditProfileState={setEditProfileState}
                />
              ) : (
                <>
                  {profileInfoNoPendingStatus === 'pending' ? (
                    <div className='big-spinner'>
                      <Spinner />
                    </div>
                  ) : (
                    <ProfileViewSection
                      profileInfoNoPendingData={profileInfoNoPendingData}
                      setEditProfileState={setEditProfileState}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ profileReducer }: CombineInterface) => ({
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  profileInfoNoPendingAction: () => dispatch(ProfileInfoNoPendingAction()),
  profileEditInfoAction: (body: ProfileEditBody) => dispatch(ProfileEditInfoAction(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ProfileView)
