import React, { FC, useEffect } from 'react'
import { ChangePasswordInterfaces } from './interfaces'
import { useNavigate } from 'react-router-dom'
import { Input } from 'antd'
import PasswordPopover from '../Login/SignUp/PasswordPopover'

const ChangePasswordFields: FC<ChangePasswordInterfaces> = ({
  handleChangePassword,
  formDataChangePassword,
  passwordState,
  handleFocusValidation,
  buttonClick,
  confirmPasswordData,
  confirmPasswordStatus,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (confirmPasswordStatus === 'success') {
      navigate('/sign-in')
    }
  }, [confirmPasswordStatus])
  return (
    <>
      <label>Code</label>
      <Input
        onChange={(e) => handleChangePassword(e, 'code')}
        type='number'
        placeholder='Enter your code'
      />
      {formDataChangePassword.code === '' && buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid code</p>
      ) : (
        <p className='error-message message-desable'>*Please enter a valid code</p>
      )}
      <label>New Password</label>
      <Input
        onChange={(e) => handleChangePassword(e, 'newPassword')}
        type='password'
        onFocus={() =>
          handleFocusValidation('focus', 'newPassword', formDataChangePassword.newPassword)
        }
        onBlur={() =>
          handleFocusValidation('blur', 'newPassword', formDataChangePassword.newPassword)
        }
        placeholder='New Password'
      />
      {formDataChangePassword.newPassword === '' && buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid current password</p>
      ) : (
        <p className='error-message message-desable'>*Please enter a valid current password</p>
      )}
      <div className='popover_box'>
        {passwordState && <PasswordPopover password={formDataChangePassword.newPassword} />}
      </div>
      <label>Confirm Password</label>
      <Input
        onChange={(e) => handleChangePassword(e, 'confirmPassword')}
        type='password'
        placeholder='Confirm Password'
      />
      {formDataChangePassword.confirmPassword === '' && buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid current password</p>
      ) : (
        <p className='error-message message-desable'>*Please enter a valid current password</p>
      )}
      {confirmPasswordData && confirmPasswordStatus !== 'pending' && buttonClick ? (
        <p className='error-message message-active'>{confirmPasswordData}</p>
      ) : (
        <p className='error-message message-desable'>{confirmPasswordData}</p>
      )}
    </>
  )
}

export default ChangePasswordFields
