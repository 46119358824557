/* eslint-disable no-empty */
import React, { FC, useState, useEffect, useRef } from 'react'
import '../styles.scss'
import { useNavigate } from 'react-router-dom'
import { HeaderInterface } from '../interfaces'
import { connect } from 'react-redux'
import { signUpReset } from '../../../store/actions/AuthActions'
import { Button, Popover } from 'antd'
import { Auth } from 'aws-amplify'
import { ProfileInfoNoPendingAction } from '../../../store/actions/ProfileAction'
import { CombineInterface } from '../../../store/reducers/interfaces'
import queryString from 'query-string'
import MobileHeader from './MobileHeader'
let opened = false

const Header: FC<HeaderInterface> = ({
  signUpResetAction,
  profileReducer: { profileInfoNoPendingData },
  profileInfoNoPendingAction,
}) => {
  const [sizeSate, setSizeState] = useState(false)
  const [mobileMenuState, setMobileMenuState] = useState(false)
  const [email, setEmail] = useState('')
  const parsed = queryString.parse(location.search)
  const navigate = useNavigate()
  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setSizeState(true)
    } else {
      setSizeState(false)
    }
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClick = () => {
    if (window.location.pathname === '/sign-in') {
      if (!parsed.firstName) {
        navigate('/sign-up')
      } else {
        navigate(
          `/sign-up?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`,
        )
      }
      signUpResetAction()
    } else {
      if (!parsed.firstName) {
        navigate('/sign-in')
      } else {
        navigate(
          `/sign-in?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`,
        )
      }
    }
  }
  const login = localStorage.getItem('login')

  const handleLogOutClick = () => {
    localStorage.clear()
    navigate('/')
  }

  useEffect(() => {
    ;(async () => {
      try {
        const user = await Auth.currentUserInfo()

        const data = user?.attributes['email']
        setEmail(data)
      } catch (error) {}
    })()
  }, [])

  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')

  useEffect(() => {
    ;(async () => {
      try {
        const user = await Auth.currentUserInfo()

        if (user) {
          if (!firstName) {
            profileInfoNoPendingAction()
          }
        }
      } catch (error) {}
    })()
  }, [firstName])

  const content = (
    <div className='popup-box'>
      <div className='info-box'>
        <img src={require('../../../assets/images/header/avatar-login.svg').default} />
        <div className='name-section'>
          <h6>
            {profileInfoNoPendingData?.data.firstName || firstName}{' '}
            {profileInfoNoPendingData?.data.lastName || lastName}
          </h6>
          <p>{email}</p>
        </div>
      </div>
      <div onClick={() => navigate('/profile-view')} className='info-box'>
        <img src={require('../../../assets/images/header/avatar-popup.svg').default} />
        <div className='name-section'>
          <p>View profile</p>
        </div>
      </div>
      <div className='info-box'>
        <img
          onClick={() => navigate('/')}
          src={require('../../../assets/images/header/logout.svg').default}
        />
        <div onClick={handleLogOutClick} className='name-section'>
          <p>Log out</p>
        </div>
      </div>
    </div>
  )

  // const openedState = false
  const handleClickMobileMenuIcon = () => {
    if (!mobileMenuState) {
      setMobileMenuState(true)
    } else {
      setMobileMenuState(false)
    }
  }

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node | null
      if (wrapperRef.current && !wrapperRef.current.contains(target) && !opened) {
        setMobileMenuState(false)
      }

      opened = false
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [mobileMenuState, wrapperRef])
  const payment = localStorage.getItem('payment')
  return (
    <>
      <div className='header'>
        <div className='header-part'>
          <div className='container'>
            <div className='header-section'>
              <img
                onClick={() => navigate('/')}
                src={require('../../../assets/images/logo/logo.svg').default}
              />
              <div className='account-section'>
                {window.location.pathname !== '/sign-up' &&
                window.location.pathname !== '/sign-in' ? (
                  <>
                    {sizeSate ? (
                      <div ref={wrapperRef}>
                        <img
                          onClick={handleClickMobileMenuIcon}
                          src={require('../../../assets/images/header/header-burger.svg').default}
                        />
                      </div>
                    ) : (
                      <ul>
                        <li
                          className={window.location.pathname === '/' ? 'active' : ''}
                          onClick={() => navigate('/')}
                        >
                          Home
                        </li>
                        <li
                          className={window.location.pathname === '/contact' ? 'active' : ''}
                          onClick={() => navigate('/contact')}
                        >
                          Contact
                        </li>
                        {!payment ? (
                          <li
                            className={window.location.pathname === '/subscribe' ? 'active' : ''}
                            onClick={() => navigate('/subscribe')}
                          >
                            Pricing
                          </li>
                        ) : (
                          ''
                        )}
                        {login && (
                          <li
                            className={
                              window.location.pathname === '/search-result' ? 'active' : ''
                            }
                            onClick={() => navigate('/search-result')}
                          >
                            Search Results
                          </li>
                        )}
                        {login ? (
                          <>
                            {' '}
                            <Popover placement='bottom' content={content}>
                              <li>
                                <img
                                  src={
                                    require('../../../assets/images/header/avatar-login.svg')
                                      .default
                                  }
                                />
                              </li>
                            </Popover>
                          </>
                        ) : (
                          <>
                            <Button onClick={() => navigate('/sign-up')}>
                              Get Started{' '}
                              <img
                                src={require('../../../assets/images/header/avatar.svg').default}
                              />
                            </Button>
                          </>
                        )}
                      </ul>
                    )}
                  </>
                ) : (
                  <>
                    <p>Already have an account?</p>
                    <p>
                      <span onClick={handleClick}>
                        {window.location.pathname === '/sign-in' ? 'Sign Up' : 'Sign in'}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div
            style={{
              overflow: 'hidden',
              height: mobileMenuState && sizeSate ? (login ? 290 : 230) : 0,
              transition: '.3s all ease',
            }}
          >
            <MobileHeader
              firstName={profileInfoNoPendingData?.data.firstName || ''}
              lastName={profileInfoNoPendingData?.data.lastName || ''}
              login={login}
              content={content}
              handleLogOutClick={handleLogOutClick}
              payment={payment || ''}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateFromProps = ({ profileReducer }: CombineInterface) => ({
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signUpResetAction: () => dispatch(signUpReset()),
  profileInfoNoPendingAction: () => dispatch(ProfileInfoNoPendingAction()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Header)
