import React, { FC, useState, useEffect } from 'react'
import './styles.scss'
import ProfileTopSection from '../ProfileTopSection'
import { Input, Button } from 'antd'
import { ProfileSecuritySectionInterface } from './interfaces'
import Spinner from '../../../../core-ui/Spinner'
import PasswordPopover from '../../../Login/SignUp/PasswordPopover'
import { validatePassword } from '../../../../utils/regex'
import SuccessMessage from '../../../SuccessMessage/Index'

let passwordCheckOpened = false

const ProfileSecuritySection: FC<ProfileSecuritySectionInterface> = ({
  changePasswordAction,
  changePasswordStatus,
  changePasswordError,
  changePasswordResetAction,
}) => {
  const [passwordState, setPasswordState] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
  })

  useEffect(() => {
    return () => {
      changePasswordResetAction()
    }
  }, [])

  const handleChange = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    setButtonClick(false)
    const { value } = event_.target
    if (stateName === 'newPassword' && passwordState && validatePassword(event_.target.value)) {
      setPasswordState(false)
    } else if (
      stateName === 'newPassword' &&
      !validatePassword(event_.target.value) &&
      !passwordState &&
      passwordCheckOpened
    ) {
      setPasswordState(true)
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleFocusValidation = (val: string, key: string, value: string) => {
    if (key === 'newPassword') {
      if (val === 'focus') {
        passwordCheckOpened = true
        if (!validatePassword(value)) {
          setPasswordState(true)
        }
      } else {
        setPasswordState(false)
      }
    }
  }

  const handleSaveClick = () => {
    setButtonClick(true)

    if (
      formData.oldPassword &&
      formData.newPassword &&
      formData.oldPassword === formData.newPassword
    ) {
      changePasswordAction({
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      })
    }
  }

  return (
    <div className='container'>
      <div className='profile'>
        {changePasswordStatus === 'success' ? (
          <div>
            <SuccessMessage message={'Your password has been updated'} />
          </div>
        ) : (
          ''
        )}
        <ProfileTopSection title='Change Password' type='security' buttonTitle='Edit' />
        <div className='security-box'>
          <label>Current Password*</label>
          <Input type='password' onChange={(e) => handleChange(e, 'oldPassword')} />
          {formData.oldPassword === '' && buttonClick ? (
            <p className='error-message message-active'>*Please enter a valid current password</p>
          ) : (
            <p className='error-message message-desable'>*Please enter a valid current password</p>
          )}
          <label>New Password*</label>
          <Input
            onFocus={() => handleFocusValidation('focus', 'newPassword', formData.newPassword)}
            onBlur={() => handleFocusValidation('blur', 'newPassword', formData.newPassword)}
            type='password'
            onChange={(e) => handleChange(e, 'newPassword')}
          />
          {formData.newPassword === '' && buttonClick ? (
            <p className='error-message message-active'>*Please enter a valid new password</p>
          ) : (
            <p className='error-message message-desable'>*Please enter a valid new password</p>
          )}
          {passwordState && <PasswordPopover password={formData.newPassword} />}
          {changePasswordError && buttonClick && changePasswordStatus !== 'pending' ? (
            <p className='error-message message-active'>{changePasswordError}</p>
          ) : (
            <p className='error-message message-desable'></p>
          )}
          <div className='button-box'>
            <Button onClick={handleSaveClick}>
              {changePasswordStatus === 'pending' ? <Spinner /> : 'Save'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileSecuritySection
