import React from 'react'

const AnonymousPrivate = () => {
  return (
    <>
      <div className='anonymous-private'>
        <h1>Your search is Anonymous and Private</h1>
        <p>
          We scour data from specialized sources to give you valuable information in one location.
        </p>
        <div className='can-trust'>
          <div>
            <img src={require('../../assets/images/home/result.svg').default} />
            <p>
              Results you <br /> can trust
            </p>
          </div>
          <div>
            <img src={require('../../assets/images/home/customer.svg').default} />
            <p>
              Customer identity <br /> protection
            </p>
          </div>
          <div>
            <img src={require('../../assets/images/home/avoid.svg').default} />
            <p>
              Avoid phone <br /> scammers
            </p>
          </div>
          <div>
            <img src={require('../../assets/images/home/privatInfo.svg').default} />
            <p>
              Find and remove your exposed <br /> private information
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnonymousPrivate
