import { AxiosResponse } from 'axios'
import { HTTP } from '../../utils/http'
import {
  PROFILE,
  PROFILE_INFO_NO_PENDING,
  PROFILE_RESULTS_NO_PENDING,
  PROFILE_EDIT,
  DELETE_ACCOUNT,
  CANCEL_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
} from '../constants'
import { profileInfoNoPendingDataType } from '../reducers/interfaces'
import { ProfileBody, ProfileEditBody } from './interfaces'
import queryString from 'query-string'

export const ProfileAction = (body: ProfileBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: PROFILE.PENDING })
    const response = await HTTP.post('api/profile', body)
    dispatch({ type: PROFILE.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PROFILE.ERROR, error: error })
  }
}

export const ContactReset = () => ({
  type: PROFILE.RESET,
})

export const ProfileInfoNoPendingAction =
  (redirect?: boolean) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: PROFILE_INFO_NO_PENDING.PENDING })
      const response = (await HTTP.get(
        'api/profile',
      )) as AxiosResponse<profileInfoNoPendingDataType>
      dispatch({ type: PROFILE_INFO_NO_PENDING.SUCCESS, payload: response.data })
      if (response.data?.data?.subscription?.subscriptionType) {
        localStorage.setItem('firstName', response.data.data.firstName)
        localStorage.setItem('lastName', response.data.data.lastName)
        localStorage.setItem('payment', 'true')
      }
      if (redirect) {
        const parsed = queryString.parse(location.search)
        if (!parsed.firstName) {
          window.location.href = '/subscribe'
        } else {
          window.location.href = `/subscribe?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`
        }
      }
    } catch (error) {
      dispatch({ type: PROFILE_INFO_NO_PENDING.ERROR, error: error })
    }
  }

export const ProfileInfoNoPendingResultsAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: PROFILE_RESULTS_NO_PENDING.PENDING })
    const response = await HTTP.get('api/profile/data-history')
    dispatch({ type: PROFILE_RESULTS_NO_PENDING.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PROFILE_RESULTS_NO_PENDING.ERROR, error: error })
  }
}

export const ProfileEditInfoAction =
  (body: ProfileEditBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: PROFILE_EDIT.PENDING })
      const response = await HTTP.put('api/profile', body)
      dispatch({ type: PROFILE_EDIT.SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({ type: PROFILE_EDIT.ERROR, error: error })
    }
  }

export const AccountDeleteAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: DELETE_ACCOUNT.PENDING })
    const response = await HTTP.delete('api/profile')
    dispatch({ type: DELETE_ACCOUNT.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: DELETE_ACCOUNT.ERROR, error: error })
  }
}

export const SubscriptionCnacelAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION.PENDING })
    const response = await HTTP.post('api/payment/subscription/pause', {})
    dispatch({ type: CANCEL_SUBSCRIPTION.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: CANCEL_SUBSCRIPTION.ERROR, error: error })
  }
}

export const SubscriptionCnacelResetAction = () => ({
  type: CANCEL_SUBSCRIPTION.RESET,
})

export const SubscriptionResumeAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: RESUME_SUBSCRIPTION.PENDING })
    const response = await HTTP.post('api/payment/subscription/resume', {})
    dispatch({ type: RESUME_SUBSCRIPTION.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: RESUME_SUBSCRIPTION.ERROR, error: error })
  }
}

export const SubscriptionResumeResetAction = () => ({
  type: RESUME_SUBSCRIPTION.RESET,
})
