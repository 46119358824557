import React, { FC } from 'react'
import ProfileTopSection from '../ProfileTopSection'
import ProfileEditFields from './ProfileEditFileds'
import { ProfileEditSectionInterface } from '../ProfileView/interface'

const ProfileEditSection: FC<ProfileEditSectionInterface> = ({
  profileEditInfoAction,
  profileEditStatus,
  profileEditData,
  profileInfoNoPendingData,
  setEditProfileState,
}) => {
  return (
    <>
      <div className='profile'>
        <ProfileTopSection
          type='edit'
          title='Profile'
          description='The limit for changing your info is 3 times each month and 9 times every 6 months.'
        />
        <ProfileEditFields
          profileEditStatus={profileEditStatus}
          profileEditInfoAction={profileEditInfoAction}
          profileEditData={profileEditData}
          profileInfoNoPendingData={profileInfoNoPendingData}
          setEditProfileState={setEditProfileState}
        />
      </div>
    </>
  )
}

export default ProfileEditSection
