import React, { useState, FC } from 'react'
import '../SignUp/styles.scss'
import { connect } from 'react-redux'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { SignInInterfaces } from './interfaces'
import SignInFields from './SignIn'
import { SignInInterface } from '../../../store/actions/interfaces'
import { signIn } from '../../../store/actions/AuthActions'
import InnerPage from '../../InnerPage'

const SignIn: FC<SignInInterfaces> = ({
  authReducer: { signInStatus, signInData },
  signInAction,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  return (
    <>
      <InnerPage>
        <div className='container'>
          <div className='signIn-section sign-up-section'>
            <div className='sign-in-box sign-up-box'>
              <SignInFields
                setFormData={setFormData}
                formData={formData}
                signInStatus={signInStatus}
                signInAction={signInAction}
                signInData={signInData}
              />
            </div>
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signInAction: (body: SignInInterface) => dispatch(signIn(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(SignIn)
