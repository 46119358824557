import { HTTP } from '../../utils/http'
import { RESULT_LIST, RESULT_REMOVE } from '../constants'
import { ResultBody, ResultRemoveBody } from './interfaces'

export const ResultList = (body: ResultBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: RESULT_LIST.PENDING })
    const response = await HTTP.post('api/data/search-status', body)
    dispatch({ type: RESULT_LIST.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: RESULT_LIST.ERROR, payload: error })
  }
}

export const ResultListReset = () => ({
  type: RESULT_LIST.RESET,
})

export const ResultRemoveAction =
  (body: ResultRemoveBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: RESULT_REMOVE.PENDING })
      const response = await HTTP.post('api/data/remove-profile', body)
      dispatch({ type: RESULT_REMOVE.SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({ type: RESULT_REMOVE.ERROR, payload: error })
    }
  }
