import React, { FC, useEffect, useState } from 'react'
import './styles.scss'
import InnerPage from '../../../InnerPage'
import ProfileNavbar from '../ProfileNavbar'
import ProfilePlanSection from './ProfilePlanSection'
import { connect } from 'react-redux'
import {
  AccountDeleteAction,
  ProfileInfoNoPendingAction,
  SubscriptionCnacelAction,
  SubscriptionCnacelResetAction,
  SubscriptionResumeAction,
  SubscriptionResumeResetAction,
} from '../../../../store/actions/ProfileAction'
import { CombineInterface } from '../../../../store/reducers/interfaces'
import { ProfilePlanInterface } from './interfaces'
import {
  PaymentSubscriptionUpgrate,
  SubscibeUpgrateReset,
} from '../../../../store/actions/SubscribeActions'
import { PaymentSubscriptionUpgrateBody } from '../../../../store/actions/interfaces'
import Spinner from '../../../../core-ui/Spinner'
import DeleteModal from './DeleteModal'

const ProfilePlan: FC<ProfilePlanInterface> = ({
  profileReducer: {
    accountDeleteStatus,
    cancelSubscriptionStatus,
    profileInfoNoPendingData,
    profileInfoNoPendingStatus,
    cancelSubscriptionData,
    resumeSubscriptionStatus,
  },
  accountDeleteAction,
  subscriptionCnacelAction,
  profileInfoNoPendingAction,
  paymentSubscriptionUpgrate,
  subscibeUpgrateReset,
  subscriptionCnacelResetAction,
  subscribeReducer: { subscriptionUpgrateStatus, subscriptionUpgrateData },
  subscriptionResumeAction,
  subscriptionResumeResetAction,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    profileInfoNoPendingAction()
  }, [])

  useEffect(() => {
    if (
      subscriptionUpgrateStatus === 'success' ||
      cancelSubscriptionStatus === 'success' ||
      resumeSubscriptionStatus === 'success'
    ) {
      subscriptionResumeResetAction()
      subscriptionCnacelResetAction()
      profileInfoNoPendingAction()
    }
  }, [subscriptionUpgrateStatus, cancelSubscriptionStatus, resumeSubscriptionStatus])

  return (
    <>
      <InnerPage>
        <div className='profile-section'>
          <div className='container'>
            <div className='profile-section-child'>
              <ProfileNavbar />
              {profileInfoNoPendingStatus === 'pending' ? (
                <>
                  <div className='big-spinner'>
                    <Spinner />
                  </div>
                </>
              ) : (
                <>
                  <ProfilePlanSection
                    accountDeleteStatus={accountDeleteStatus}
                    subscriptionCnacelAction={subscriptionCnacelAction}
                    cancelSubscriptionStatus={cancelSubscriptionStatus}
                    profileInfoNoPendingData={profileInfoNoPendingData}
                    paymentSubscriptionUpgrate={paymentSubscriptionUpgrate}
                    subscriptionUpgrateStatus={subscriptionUpgrateStatus}
                    profileInfoNoPendingStatus={profileInfoNoPendingStatus}
                    subscibeUpgrateReset={subscibeUpgrateReset}
                    subscriptionUpgrateData={subscriptionUpgrateData?.data?.message || ''}
                    successMessage={cancelSubscriptionData?.data?.message || ''}
                    errorMessage={cancelSubscriptionData?.error || ''}
                    subscriptionCnacelResetAction={subscriptionCnacelResetAction}
                    resumeSubscriptionStatus={resumeSubscriptionStatus}
                    subscriptionResumeAction={subscriptionResumeAction}
                    setModalOpen={setModalOpen}
                  />
                  <DeleteModal
                    onSave={() => setModalOpen(false)}
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    accountDeleteStatus={accountDeleteStatus}
                    accountDeleteAction={accountDeleteAction}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ profileReducer, subscribeReducer }: CombineInterface) => ({
  profileReducer,
  subscribeReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  accountDeleteAction: () => dispatch(AccountDeleteAction()),
  subscriptionCnacelAction: () => dispatch(SubscriptionCnacelAction()),
  profileInfoNoPendingAction: () => dispatch(ProfileInfoNoPendingAction()),
  paymentSubscriptionUpgrate: (body: PaymentSubscriptionUpgrateBody) =>
    dispatch(PaymentSubscriptionUpgrate(body)),
  subscibeUpgrateReset: () => dispatch(SubscibeUpgrateReset()),
  subscriptionCnacelResetAction: () => dispatch(SubscriptionCnacelResetAction()),
  subscriptionResumeAction: () => dispatch(SubscriptionResumeAction()),
  subscriptionResumeResetAction: () => dispatch(SubscriptionResumeResetAction()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ProfilePlan)
