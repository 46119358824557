import React from 'react'

const Details = () => {
  return (
    <div className='details'>
      <div className='details-box'>
        <img src={require('../../assets/images/home/details.svg').default} />
        <h1>Enter Details</h1>
        <p>We collect your data & encrypt your identity</p>
      </div>
      <img className='vector' src={require('../../assets/images/home/vector-first.svg').default} />
      <div className='details-box'>
        <img src={require('../../assets/images/home/analyze.svg').default} />
        <h1>We Analyze</h1>
        <p>We will search over 70 data broker sites</p>
      </div>
      <img className='vector' src={require('../../assets/images/home/vector-second.svg').default} />
      <div className='details-box'>
        <img src={require('../../assets/images/home/results.svg').default} />
        <h1>Results</h1>
        <p>All sites that have your information</p>
      </div>
    </div>
  )
}

export default Details
