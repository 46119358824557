import React, { FC, useState, useEffect } from 'react'
import { ResultTableLogedinInterface } from './interfaces'
import { Button } from 'antd'
import Spinner from '../../core-ui/Spinner'
import { resultListReducerData } from '../../store/reducers/interfaces'
import { useSearchParams } from 'react-router-dom'

const ResultTableLogedin: FC<ResultTableLogedinInterface> = ({
  resultListData,
  resultRemoveAction,
  resultRemoveStatus,
}) => {
  const [searchParams] = useSearchParams()
  const [isState, setIsState] = useState('')
  const [newDataState, setNewDataState] = useState<resultListReducerData['data']['results']>([])
  useEffect(() => {
    if (resultListData?.data?.results) {
      const newState = [...resultListData.data.results]
      for (const [idx, item] of resultListData.data.results.entries()) {
        for (const data of Object.values(item.details)) {
          if (data.length === 1) {
            newState.push(item)
            newState.splice(idx, 1)
          }
        }
      }
      setNewDataState(newState)
    }
  }, [resultListData?.data?.results])
  const handleRemoveClick = (url: string) => {
    setIsState(url)
    const body = {
      profileUrl: url,
    }
    resultRemoveAction(body)
  }
  return (
    <>
      <div className='container result-section'>
        <div className='result-table result-custom-table result-custom-table result-custom-section'>
          {/* <div className='logo-box'>
            <img src={require('../../assets/images/result/check.svg').default} />
            <h6>James Smith</h6>
          </div>
          <p className='text-found'>
            Found 835 people in <span>California, New Jersey, New York</span> and 47 other states
          </p> */}
          <div className='top-box'>
            <div className='check-section'>
              <p>Website</p>
            </div>
            <p>Result</p>
          </div>

          {newDataState.map(({ details, website }) => (
            <>
              {Object.values(details).map((items) => (
                <>
                  {items.map(({ age, address, state, city, firstName, lastName, url }) => (
                    <>
                      {age ? (
                        <>
                          <div className='global-box'>
                            <div className='check-state'>
                              <div className='info-state'>
                                <img
                                  className='vector'
                                  src={
                                    require('../../assets/images/result/logo-main 1.svg').default
                                  }
                                />
                                <p>{website}</p>
                              </div>
                            </div>
                            <div className='all-section'>
                              <div className='middle-box'>
                                <span className='name-state'>
                                  {firstName} {lastName}
                                </span>
                                <p>
                                  Age:<span>{age}</span>
                                </p>
                                <p>
                                  Location:
                                  <span>
                                    {address}, {state}, {city}
                                  </span>
                                </p>
                              </div>
                              {searchParams.get('firstName') === firstName &&
                              searchParams.get('lastName') === lastName ? (
                                <div className='extra-match'>
                                  <p>Exact Match</p>
                                </div>
                              ) : (
                                <div className='extra-match parial-match'>
                                  <p>Partial Match</p>
                                </div>
                              )}
                            </div>
                            <div
                              onClick={() => handleRemoveClick(url)}
                              className='button-box complete'
                            >
                              <div className='button-box-part'>
                                <p>Removal Request Complete</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {items.length === 1 && (
                            <div className='global-box'>
                              <div className='check-state'>
                                {/* <Checkbox className='scales-checkbox' /> */}
                                <div className='info-state'>
                                  <img
                                    className='vector'
                                    src={
                                      require('../../assets/images/result/logo-main 1.svg').default
                                    }
                                  />
                                  <p>{website}</p>
                                </div>
                              </div>
                              <div className='all-section global-match-found'>
                                <div className='match-found'>
                                  <p>No match found</p>
                                </div>
                              </div>
                              <div
                                onClick={() => handleRemoveClick(url)}
                                className='button-box button-box-match'
                              >
                                <Button>
                                  {isState === url && resultRemoveStatus === 'pending' ? (
                                    <Spinner />
                                  ) : (
                                    'Remove'
                                  )}
                                </Button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              ))}
            </>
          ))}
          {/* <div className='global-box'>
            <div className='check-state'>
              <Checkbox className='scales-checkbox' />
              <div className='info-state'>
                <img
                  className='vector'
                  src={require('../../assets/images/result/logo-main 1.svg').default}
                />
                <p>www.radaris.com</p>
              </div>
            </div>
            <div className='all-section'>
              <div className='middle-box'>
                <span className='name-state'>James Smith</span>
                <p>
                  Age:<span>50</span>
                </p>
                <p>
                  Location:<span>Marina Del Rey, CA, Los Angeles</span>
                </p>
              </div>
              <div className='button-box'>
                <Button>Remove</Button>
                <Button>Keep</Button>
                <Button>Hide</Button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default ResultTableLogedin
