import { combineReducers } from 'redux'
import authReducer from './AuthReducers'
import searchReducer from './SearchReducers'
import resultReducer from './ResultReducers'
import subscribeReducer from './SubscribeReducers'
import contactReducer from './ContactReducers'
import profileReducer from './ProfileReducers'

export type DispatchPayloadType = {
  type: string
  payload?: any
  error?: any
}

const rootReducer = combineReducers({
  authReducer,
  searchReducer,
  resultReducer,
  subscribeReducer,
  contactReducer,
  profileReducer,
})

export default rootReducer
