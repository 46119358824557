export const emailRegex = new RegExp(
  /^(([^<>()\],;:\s@]+([^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i,
)

export const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#$%^&*])(?=.{8,})',
)

export const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/

export const validatePassword = (password: string) => {
  // return passwordRegex.test(password);
  if (password.length < 8) {
    return false
  }

  if (!/\d/.test(password)) {
    return false
  }

  if (!/[A-Z]/.test(password)) {
    return false
  }

  if (!/[a-z]/.test(password)) {
    return false
  }

  if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
    return false
  }

  return true
}
