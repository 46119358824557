import React, { FC, useEffect } from 'react'
import './styles.scss'
import InnerPage from '../../InnerPage'
import { connect } from 'react-redux'
import { CombineInterface } from '../../../store/reducers/interfaces'
import ProfileFieldsSection from './ProfileFieldsSection'
import { ProfileInterface } from './interfaces'
import { ProfileAction, ProfileInfoNoPendingAction } from '../../../store/actions/ProfileAction'
import { ProfileBody } from '../../../store/actions/interfaces'
import Spinner from '../../../core-ui/Spinner'
import { useNavigate } from 'react-router'

const Profile: FC<ProfileInterface> = ({
  profileReducer: { profileStatus, profileResultNoPendingStatus, profileInfoNoPendingData },
  profileAction,
  profileInfoNoPendingAction,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    profileInfoNoPendingAction()
  }, [])

  useEffect(() => {
    const payment = localStorage.getItem('payment')
    if (!payment) {
      navigate('/subscribe')
    }
  }, [])

  useEffect(() => {
    if (profileStatus === 'success') {
      profileInfoNoPendingAction()
    }
  }, [profileStatus])

  useEffect(() => {
    if (profileInfoNoPendingData && Object.values(profileInfoNoPendingData.data).length) {
      navigate('/search-result')
    }
  }, [profileInfoNoPendingData?.data?.firstName])

  return (
    <>
      <InnerPage>
        <>
          {profileResultNoPendingStatus === 'pending' ? (
            <div className='big-spinner'>
              <Spinner />
            </div>
          ) : (
            <>
              <ProfileFieldsSection profileAction={profileAction} profileStatus={profileStatus} />
            </>
          )}
        </>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ profileReducer }: CombineInterface) => ({
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  profileAction: (body: ProfileBody) => dispatch(ProfileAction(body)),
  profileInfoNoPendingAction: () => dispatch(ProfileInfoNoPendingAction()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Profile)
