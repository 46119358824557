import React from 'react'

const Statistics = () => {
  return (
    <div className='statistics-container'>
      <div className='container'>
        <div className='statistics-box'>
          <h1>Why Choose InfoVanisher?</h1>
          <p>
            We’re more than people search. Our users trust us day in and day out for many reasons.
          </p>
          <div className='statistics-content'>
            <div className='statistic-item'>
              <h5>400m</h5>
              <p>People Records</p>
            </div>
            <div className='statistic-line' />
            <div className='statistic-item'>
              <h5>250m</h5>
              <p>Happy Customers</p>
            </div>
            <div className='statistic-line' />
            <div className='statistic-item'>
              <h5>100%</h5>
              <p>Secure Database</p>
            </div>
            <div className='statistic-line' />
            <div className='statistic-item'>
              <h5>10y</h5>
              <p>Over 10 Years of Success</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistics
