import React, { useState, useEffect } from 'react'

const Footer = () => {
  const [sizeSate, setSizeState] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setSizeState(true)
    } else {
      setSizeState(false)
    }
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className='footer'>
      <div className='container'>
        {sizeSate ? (
          <div className='footer-section'>
            <img alt='' src={require('../../../assets/images/header/avatar-light.svg').default} />
            <div className='footer-content'>
              <div className='footer-items'>
                <a href='/contact'>Contact Us</a>
                <a href='/privacy-policy'>Privacy Policy</a>
                <a href='/terms'>Terms of Use</a>
              </div>
            </div>
            <p>© 2023 InfoVanisher</p>
          </div>
        ) : (
          <div className='footer-content'>
            <img alt='' src={require('../../../assets/images/header/avatar-light.svg').default} />
            <div className='footer-items'>
              <a href='/contact'>Contact Us</a>
              <a href='/privacy-policy'>Privacy Policy</a>
              <a href='/terms'>Terms of Use</a>
            </div>
            <p>© 2023 InfoVanisher</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Footer
