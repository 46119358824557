import React, { FC } from 'react'
import InnerPage from '../InnerPage'
import './styles.scss'
import InformationInternet from './InformationInternet'
import AnonymousPrivate from './AnonymousPrivate'
import { CombineInterface } from '../../store/reducers/interfaces'
import Statistics from './Statistics'
import SearchSection from './Search'
import { Search, SearchReset } from '../../store/actions/SearchAction'
import Details from './Details'
import { connect } from 'react-redux'
import { SearchBody } from '../../store/actions/interfaces'
import { HomeInterface } from './interfaces'

const Home: FC<HomeInterface> = ({
  searchReducer: { searchStatus, searchData },
  searchAction,
  searchResetAction,
}) => {
  return (
    <>
      <InnerPage>
        <InformationInternet
          requestId={searchData?.data.request_id}
          searchAction={searchAction}
          searchStatus={searchStatus}
          searchResetAction={searchResetAction}
        />
        <AnonymousPrivate />
        <Details />
        <Statistics />
        <SearchSection />
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ searchReducer }: CombineInterface) => ({
  searchReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  searchAction: (body: SearchBody) => dispatch(Search(body)),
  searchResetAction: () => dispatch(SearchReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Home)
