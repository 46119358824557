/* eslint-disable camelcase */
import { DispatchPayloadType } from '../reducers'
import {
  SignUpInterface,
  VerifyPhoneInterface,
  SignInInterface,
  ForgotPasswordInterface,
  ChangePasswordInterface,
  ConfirmForgotPasswordInterface,
} from './interfaces'
import { Auth } from 'aws-amplify'
import { HTTP } from '../../utils/http'
import {
  SIGN_UP,
  VERIFY_PHONE,
  SIGN_IN,
  FORGOT_PASSWORD,
  USER_INFO,
  CHANGE_PASSWORD,
  RESEND_SIGN_UP,
  CONFIRM_FORGOT_PASSWORD,
} from '../constants'
import { ProfileInfoNoPendingAction } from './ProfileAction'
import queryString from 'query-string'
import axios, { AxiosResponse } from 'axios'
import { SubscribeReducersInterface, profileInfoNoPendingDataType } from '../reducers/interfaces'

export const signUp =
  ({ password, email }: SignUpInterface) =>
  async (dispatch: (event: any) => void) => {
    try {
      dispatch({
        type: SIGN_UP.PENDING,
      })
      const attributes = {
        email,
      }

      const response = await Auth.signUp({
        attributes,
        password,
        username: email,
      })
      dispatch({
        type: SIGN_UP.SUCCESS,
        payload: response,
      })
    } catch (error) {
      dispatch({
        type: SIGN_UP.ERROR,
        error: error,
      })
    }
  }

export const signUpReset = () => ({
  type: SIGN_UP.RESET,
})

export const verifyPhone =
  ({ otpCode, email, password }: VerifyPhoneInterface) =>
  async (dispatch: (event: any) => void) => {
    try {
      dispatch({
        type: VERIFY_PHONE.PENDING,
      })

      await Auth.confirmSignUp(email, otpCode)
      await Auth.signIn(email, password)
      dispatch({
        type: SIGN_IN.SUCCESS,
      })
      const parsed = queryString.parse(location.search)
      if (!parsed.firstName) {
        window.location.href = '/subscribe'
      } else {
        window.location.href = `/subscribe?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`
      }
      localStorage.setItem('login', 'true')
      localStorage.setItem('email', email)
      dispatch({
        type: VERIFY_PHONE.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: VERIFY_PHONE.ERROR,
        error: error,
      })
    }
  }

export const GetUserInfo = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: USER_INFO.PENDING })
    const response = await HTTP.get('api/user/info')
    dispatch({ type: USER_INFO.SUCCESS, payload: response })
  } catch (error) {
    dispatch({ type: USER_INFO.ERROR, payload: error })
  }
}

export const isLoggedIn = () => async (dispatch: (event: DispatchPayloadType) => void) => {
  dispatch({
    type: VERIFY_PHONE.SUCCESS,
  })
  window.location.href = '/'
  localStorage.setItem('login', 'true')
}

export const signIn =
  ({ password, email }: SignInInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: SIGN_IN.PENDING,
      })
      await Auth.signIn(email, password)

      const subscriptionURL = `${process.env.REACT_APP_HOST}/api/payment/subscription`
      const profileRURL = `${process.env.REACT_APP_HOST}/api/profile`
      const token = await HTTP.refreshToken()

      const headers = {
        'Content-Type': 'application/json',
        Authorization: token || '',
      }

      const requests = [
        axios.get(subscriptionURL, { headers }),
        axios.get(profileRURL, { headers }),
      ]

      const [subscriptionResponse, profileResponse] = (await Promise.all(requests)) as [
        AxiosResponse<SubscribeReducersInterface['getSubscribeData']>,
        AxiosResponse<profileInfoNoPendingDataType>,
      ]

      localStorage.setItem('login', 'true')
      const parsed = queryString.parse(location.search)
      if (
        subscriptionResponse.data?.data &&
        Object.values(subscriptionResponse.data?.data).length
      ) {
        localStorage.setItem('payment', 'true')
        if (profileResponse.data.data && Object.values(profileResponse.data.data).length) {
          window.location.href = '/search-result'
        } else {
          if (!parsed.firstName) {
            window.location.href = '/profile'
          } else {
            window.location.href = `/profile?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`
          }
        }
      } else {
        const funcDispatch = dispatch as (event: any) => void
        funcDispatch(ProfileInfoNoPendingAction(true))
      }
      // dispatch({
      //   type: SIGN_IN.SUCCESS,
      // })
    } catch (error) {
      dispatch({
        type: SIGN_IN.ERROR,
        error: error,
      })
    }
  }

export const forgotPassword =
  ({ email }: ForgotPasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: FORGOT_PASSWORD.PENDING,
      })
      await Auth.forgotPassword(email)
      dispatch({
        type: FORGOT_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD.ERROR,
        error: error,
      })
    }
  }

export const forgotPasswordReset = () => ({
  type: FORGOT_PASSWORD.RESET,
})

export const ChangePassword =
  ({ oldPassword, newPassword }: ChangePasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      const user = await Auth.currentAuthenticatedUser()

      dispatch({
        type: CHANGE_PASSWORD.PENDING,
      })
      await Auth.changePassword(user, oldPassword, newPassword)
      dispatch({
        type: CHANGE_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD.ERROR,
        error: error,
      })
    }
  }

export const ChangePasswordReset = () => ({
  type: CHANGE_PASSWORD.RESET,
})

export const ResendSignUp =
  (username: string) => async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: RESEND_SIGN_UP.PENDING,
      })
      await Auth.resendSignUp(username)
      dispatch({
        type: RESEND_SIGN_UP.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: RESEND_SIGN_UP.ERROR,
        error: error,
      })
    }
  }

export const confirmForgotPassword =
  ({ email, code, password }: ConfirmForgotPasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.PENDING,
      })
      await Auth.forgotPasswordSubmit(email, code, password)
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.ERROR,
        error: error,
      })
    }
  }

export const confirmForgotPasswordReset = () => ({
  type: CONFIRM_FORGOT_PASSWORD.RESET,
})
