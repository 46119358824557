import React, { FC, useState, useEffect } from 'react'
import { CheckoutFormInterface } from './interfaces'
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import queryString from 'query-string'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Spinner from '../../core-ui/Spinner'

const CheckoutForm: FC<CheckoutFormInterface> = ({
  stepState,
  subscibeAction,
  subscribeStatus,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const parsed = queryString.parse(location.search)

  const [loaderStateStripe, setLoaderStateStripe] = useState(false)
  const email = localStorage.getItem('email')

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any) => {
    setLoaderStateStripe(true)
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardNumberElement)
    if (!cardElement) {
      return
    }
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      // eslint-disable-next-line camelcase
      billing_details: {
        email: email || '',
      },
    })
    if (payload.paymentMethod?.id) {
      setLoaderStateStripe(false)
      const body = {
        subscriptionType: stepState,
        paymentMethodId: payload.paymentMethod?.id,
        username: !parsed.firstName ? undefined : (parsed.firstName as string),
      }
      subscibeAction(body)
    }
    // confirm the payment and use the new payment method
    const result = await stripe.confirmCardPayment('', {
      // eslint-disable-next-line camelcase
      payment_method: payload.paymentMethod?.id,
    })

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setLoaderStateStripe(false)
    }
  }

  useEffect(() => {
    if (subscribeStatus === 'success') {
      localStorage.setItem('payment', 'true')
      if (searchParams.get('firstName') === null) {
        navigate('/profile')
      } else {
        navigate(
          `/profile?firstName=${searchParams.get('firstName')}&lastName=${searchParams.get(
            'lastName',
          )}&city=${searchParams.get('city')}&state=${searchParams.get('state')}`,
        )
      }
    }
  }, [subscribeStatus])

  return (
    <div className='container'>
      <div className='checkout-section'>
        <div className='checkout-box'>
          <form className='stripe-box' onSubmit={handleSubmit}>
            <label>Credit or Debit card</label>
            <div className='card-element-box'>
              <CardNumberElement
                options={{
                  showIcon: true,
                  style: {
                    invalid: {
                      iconColor: 'red',
                      color: 'red',
                    },
                  },
                }}
              />
              <div className='svc-box'>
                <div>
                  <label>Expiry</label>
                  <CardExpiryElement
                    options={{
                      style: {
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                        },
                      },
                    }}
                  />
                </div>
                <div className='cvv-box'>
                  <label>CVV</label>
                  <CardCvcElement
                    options={{
                      style: {
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <button disabled={!stripe}>
              {subscribeStatus === 'pending' || loaderStateStripe ? <Spinner /> : 'Pay now'}
            </button>
          </form>
        </div>
        <p>
          By confirming your subscription, you allow InfoVanisher, Inc. to charge your card for this
          payment <br /> and future payments in accordance with their terms. You can always cancel
          your subscription.
        </p>
      </div>
    </div>
  )
}

export default CheckoutForm
