import React, { FC, useState, useEffect } from 'react'
import './styles.scss'
import InnerPage from '../InnerPage'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { CombineInterface } from '../../store/reducers/interfaces'
import SubscribeSection from '../SubscribePlan/SubsribeSection'
import PaymentPlan from './PaymentPlan'
import { GetSubscibe, Subscibe } from '../../store/actions/SubscribeActions'
import { SubscribeBody } from '../../store/actions/interfaces'
import { SubscribeInterface } from './interfaces'
import queryString from 'query-string'

const Subscribe: FC<SubscribeInterface> = ({
  subscribeReducer: { subscribeStatus, getSubscribeData },
  subscibeAction,
  profileReducer: { profileInfoNoPendingData },
  getSubscibeAction,
}) => {
  const parsed = queryString.parse(location.search)
  const navigate = useNavigate()
  const [paymentState, setPaymentState] = useState(false)
  const [stepState, setStepState] = useState('monthly')

  useEffect(() => {
    if (getSubscribeData?.data && Object.values(getSubscribeData.data).length) {
      if (!parsed.firstName) {
        navigate('/profile')
      } else {
        navigate(
          `/profile?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`,
        )
      }
    }
  }, [parsed, profileInfoNoPendingData?.data])

  useEffect(() => {
    if (subscribeStatus === 'success') {
      if (!parsed.firstName) {
        navigate('/profile')
      } else {
        navigate(
          `/profile?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`,
        )
      }
    }
  }, [subscribeStatus, parsed])

  useEffect(() => {
    getSubscibeAction()
  }, [])

  return (
    <>
      <InnerPage>
        <>
          {!paymentState ? (
            <>
              <SubscribeSection
                setStepState={setStepState}
                setPaymentState={setPaymentState}
                subscibeAction={subscibeAction}
                stepState={stepState}
              />
            </>
          ) : (
            <>
              <PaymentPlan
                subscribeStatus={subscribeStatus}
                stepState={stepState}
                subscibeAction={subscibeAction}
              />
            </>
          )}
        </>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ subscribeReducer, profileReducer }: CombineInterface) => ({
  subscribeReducer,
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  subscibeAction: (body: SubscribeBody) => dispatch(Subscibe(body)),
  getSubscibeAction: () => dispatch(GetSubscibe()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Subscribe)
