import React, { FC } from 'react'
import '../styles.scss'
import { Button, Popover } from 'antd'
import { MobileHeaderInterface } from '../interfaces'
import { useNavigate } from 'react-router-dom'

const MobileHeader: FC<MobileHeaderInterface> = ({
  content,
  login,
  firstName,
  lastName,
  handleLogOutClick,
  payment,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <div className='mobile-header'>
        <>
          <ul>
            <li
              className={window.location.pathname === '/' ? 'active' : ''}
              onClick={() => navigate('/')}
            >
              Home
            </li>
            <li
              className={window.location.pathname === '/contact' ? 'active' : ''}
              onClick={() => navigate('/contact')}
            >
              Contact
            </li>
            {!payment ? (
              <li
                className={window.location.pathname === '/subscribe' ? 'active' : ''}
                onClick={() => navigate('/subscribe')}
              >
                Pricing
              </li>
            ) : (
              ''
            )}
            {login && (
              <li
                className={window.location.pathname === '/search-result' ? 'active' : ''}
                onClick={() => navigate('/search-result')}
              >
                Search Results
              </li>
            )}
            {login ? (
              <>
                {!firstName ? (
                  ''
                ) : (
                  <div className='info-part'>
                    <img src={require('../../../assets/images/header/avatar-login.svg').default} />
                    <h6>
                      {firstName} {lastName}
                    </h6>
                  </div>
                )}
                <li
                  className={window.location.pathname === '/profile-view' ? 'active' : ''}
                  onClick={() => navigate('/profile-view')}
                >
                  View profile
                </li>
                <li className='last-part' onClick={() => handleLogOutClick()}>
                  Log out
                </li>
              </>
            ) : (
              <div className='info-button'>
                <Button onClick={() => navigate('/sign-up')}>
                  Get Started{' '}
                  <img src={require('../../../assets/images/header/avatar.svg').default} />
                </Button>
              </div>
            )}
            {/* {login ? (
              <>
                <li
                  className={window.location.pathname === '/subscribe' ? 'active' : ''}
                  onClick={() => handleLogOutClick()}
                >
                  View profile
                </li>
                <li
                  className={
                    window.location.pathname === '/subscribe'
                      ? 'active last-part'
                      : 'mb-3 last-part'
                  }
                  onClick={() => navigate('/subscribe')}
                >
                  Log out
                </li>
              </>
            ) : (
              ''
            )} */}
          </ul>
        </>
      </div>
    </>
  )
}

export default MobileHeader
