import React, { FC } from 'react'
import Header from './components/Header'
import { InnerPageInterface } from './interfaces'
import './styles.scss'
import Footer from './components/Footer'

const InnerPage: FC<InnerPageInterface> = ({ children }) => {
  return (
    <div className='website-container'>
      <div className=''>
        <div className='header_box'>
          <Header />
          <div>{children}</div>
        </div>
      </div>
      {!['/sign-in', '/sign-up'].includes(window.location.pathname) && <Footer />}
    </div>
  )
}

export default InnerPage
