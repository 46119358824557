import React, { FC, useEffect, useState } from 'react'
import { Button } from 'antd'
import { ProfileTopSectionInterface } from './interfaces'

const ProfileTopSection: FC<ProfileTopSectionInterface> = ({
  type,
  onClick,
  title,
  description,
  buttonTitle,
}) => {
  const [sizeSate, setSizeState] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setSizeState(true)
    } else {
      setSizeState(false)
    }
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <>
      <div className='profile-top-section'>
        <div
          className={
            type === 'plan' || type === 'switch'
              ? 'top-box-content-plan top-box-content'
              : 'top-box-content'
          }
        >
          <div className='top-child'>
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          {type === 'edit' || type === 'payment' || type === 'security' ? (
            ''
          ) : (
            <>
              {type !== 'view' ? (
                <Button className={buttonTitle === 'Switch plan' ? 'switch' : ''} onClick={onClick}>
                  <>{buttonTitle}</>
                </Button>
              ) : (
                <Button className='custom-ui' onClick={onClick}>
                  {sizeSate ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='19'
                      viewBox='0 0 18 19'
                      fill='none'
                    >
                      <path
                        d='M8.99998 15.2824H15.75M2.25 15.2824H3.50591C3.87279 15.2824 4.05624 15.2824 4.22887 15.241C4.38192 15.2042 4.52824 15.1436 4.66245 15.0614C4.81382 14.9686 4.94354 14.8389 5.20296 14.5795L14.625 5.1574C15.2463 4.53608 15.2463 3.52872 14.625 2.9074C14.0037 2.28608 12.9963 2.28608 12.375 2.9074L2.95295 12.3295C2.69352 12.5889 2.5638 12.7186 2.47104 12.87C2.3888 13.0042 2.32819 13.1505 2.29145 13.3035C2.25 13.4762 2.25 13.6596 2.25 14.0265V15.2824Z'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  ) : (
                    <>{buttonTitle}</>
                  )}
                </Button>
              )}
            </>
          )}
        </div>
        <div className='hr-box' />
      </div>
    </>
  )
}

export default ProfileTopSection
