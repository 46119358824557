import React, { FC, useEffect } from 'react'
import InnerPage from '../../InnerPage'
import { connect } from 'react-redux'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { ProfileSearchResultInterface } from '../ProfileFields/interfaces'
import {
  ProfileInfoNoPendingAction,
  ProfileInfoNoPendingResultsAction,
} from '../../../store/actions/ProfileAction'
import ProfileNoPending from './ProfileNoPending'
import { useNavigate } from 'react-router'
import Spinner from '../../../core-ui/Spinner'

const SearchResults: FC<ProfileSearchResultInterface> = ({
  profileReducer: {
    profileStatus,
    profileResultNoPendingData,
    profileInfoNoPendingData,
    profileResultNoPendingStatus,
    profileInfoNoPendingStatus,
  },
  profileInfoNoPendingAction,
  profileInfoNoPendingResultsAction,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    profileInfoNoPendingAction()
    profileInfoNoPendingResultsAction()
  }, [])
  useEffect(() => {
    if (profileStatus === 'success') {
      profileInfoNoPendingAction()
    }
  }, [profileStatus])

  useEffect(() => {
    if (
      profileInfoNoPendingData &&
      !Object.values(profileInfoNoPendingData.data).length &&
      profileInfoNoPendingStatus === 'success'
    ) {
      navigate('/profile')
    }
  }, [profileInfoNoPendingData?.data, profileInfoNoPendingStatus])

  return (
    <>
      <InnerPage>
        <>
          {profileResultNoPendingStatus === 'pending' ||
          profileInfoNoPendingStatus === 'pending' ? (
            <div className='big-spinner'>
              <Spinner />
            </div>
          ) : (
            <ProfileNoPending
              profileInfoNoPendingData={profileInfoNoPendingData}
              profileResultNoPendingData={profileResultNoPendingData}
            />
          )}
        </>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ profileReducer }: CombineInterface) => ({
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  profileInfoNoPendingAction: () => dispatch(ProfileInfoNoPendingAction()),
  profileInfoNoPendingResultsAction: () => dispatch(ProfileInfoNoPendingResultsAction()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(SearchResults)
