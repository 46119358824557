import React, { FC, useState, useEffect } from 'react'
import { Input, Select, Button, DatePicker, DatePickerProps } from 'antd'
import state from '../../../assets/state'
import './styles.scss'
import { ProfileFieldsSectionInterface } from './interfaces'
import Spinner from '../../../core-ui/Spinner'
import { emailRegex } from '../../../utils/regex'
import { useSearchParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'

const ProfileFieldsSection: FC<ProfileFieldsSectionInterface> = ({
  profileAction,
  profileStatus,
}) => {
  const [searchParams] = useSearchParams()
  const { Option } = Select
  const [buttonClick, setButtonClick] = useState(false)
  const [selectState, setSelectState] = useState(searchParams.get('state') || '')
  const [emailError, setEmailError] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [formData, setFormData] = useState({
    firstName: searchParams.get('firstName') || '',
    lastName: searchParams.get('lastName') || '',
    city: searchParams.get('city') || '',
    email: '',
  })
  useEffect(() => {
    ;(async () => {
      const user = await Auth.currentUserInfo()

      const data = user.attributes['email']
      setFormData({ ...formData, email: data })
    })()
  }, [])
  const handleChange = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    const { value } = event_.target
    if (stateName === 'email' && !emailRegex.test(value.trim())) {
      setEmailError(true)
    } else if (emailRegex.test(value.trim())) {
      setEmailError(false)
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }
  const handleChangeSelect = (event: string) => {
    setSelectState(event)
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setDateOfBirth(dateString)
  }

  const handleClick = () => {
    setButtonClick(true)
    if (formData.city && formData.email && formData.firstName && dateOfBirth && selectState) {
      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        // eslint-disable-next-line camelcase
        birth_date: dateOfBirth,
        city: formData.city,
        state: selectState,
      }
      profileAction(body)
    }
  }

  return (
    <>
      <div className='container'>
        <div className='profile-section'>
          <div className='title-box'>
            <h1>
              <span>One Last Step!</span>
            </h1>
            <h1>Create InfoVanisher Profile</h1>
            <p>Provided detail will be used to search and remove every month</p>
          </div>
          <div className='fields-section'>
            <div className='fields'>
              <div className='colum'>
                <div className='child-box'>
                  <label>First name*</label>
                  <Input
                    defaultValue={searchParams.get('firstName') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'firstName')}
                    placeholder='First name'
                  />
                  {formData.firstName === '' && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid firstName</p>
                  ) : (
                    <p className='error-message message-desable'>*Please enter a valid firstName</p>
                  )}
                </div>
                <div className='child-box'>
                  <label>Last name*</label>
                  <Input
                    defaultValue={searchParams.get('lastName') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'lastName')}
                    placeholder='Last name'
                  />
                  {formData.lastName === '' && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid lastName</p>
                  ) : (
                    <p className='error-message message-desable'>*Please enter a valid lastName</p>
                  )}
                </div>
              </div>
              <div className='colum'>
                <div className='child-box'>
                  <label>Email Address*</label>
                  <Input
                    value={formData.email}
                    disabled
                    className='input-box'
                    onChange={(e) => handleChange(e, 'email')}
                    placeholder='Enter your email'
                  />
                  {(formData.email === '' || emailError) && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid email</p>
                  ) : (
                    <p className='error-message message-desable'>*Please enter a valid email</p>
                  )}
                </div>
                <div className='child-box'>
                  <label>Birth Date*</label>
                  <DatePicker onChange={onChangeDate} />

                  {dateOfBirth === '' && buttonClick ? (
                    <p className='error-message message-active'>
                      *Please enter a valid dateOfBirth
                    </p>
                  ) : (
                    <p className='error-message message-desable'>
                      *Please enter a valid dateOfBirth
                    </p>
                  )}
                </div>
              </div>
              <div className='colum'>
                <div className='child-box'>
                  <label>City*</label>
                  <Input
                    defaultValue={searchParams.get('city') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'city')}
                    placeholder='City'
                  />
                  {formData.city === '' && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid city</p>
                  ) : (
                    <p className='error-message message-desable'>*Please enter a valid city</p>
                  )}
                </div>
                <div className='child-box'>
                  <label>State*</label>
                  <Select
                    defaultValue={searchParams.get('state') || ''}
                    suffixIcon={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                      >
                        <path
                          d='M5 7.53809L10 12.5381L15 7.53809'
                          stroke='#667085'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    }
                    placeholder='State'
                    onChange={(e) => handleChangeSelect(e)}
                  >
                    {state.map(({ name }) => (
                      <>
                        <Option value={name}>{name}</Option>
                      </>
                    ))}
                  </Select>
                  {selectState === '' && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid state</p>
                  ) : (
                    <p className='error-message message-desable'>*Please enter a valid state</p>
                  )}
                </div>
              </div>
            </div>
            <div className='button-box'>
              <Button onClick={handleClick}>
                {profileStatus === 'pending' ? <Spinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileFieldsSection
