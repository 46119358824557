import React, { useState, FC, useEffect } from 'react'
import { ForgotPasswordInterfacesFields } from './interfaces'
import { Input, Button } from 'antd'
import { emailRegex, validatePassword } from '../../utils/regex'
import Spinner from '../../core-ui/Spinner'
import ChangePasswordFields from './ChangePassword'

let passwordCheckOpened = false

const ForgotPasswordFields: FC<ForgotPasswordInterfacesFields> = ({
  forgotPasswordAction,
  forgotPasswordStatus,
  confirmPasswordStatus,
  confirmForgotPasswordAction,
  confirmPasswordData,
  forgotPasswordData,
  redirectPart,
  setRedirectPart,
}) => {
  const [errorState] = useState([
    {
      emailError: false,
    },
  ])
  const [formData, setFormData] = useState({
    email: '',
  })
  const [formDataChangePassword, setFormDataChangePassword] = useState({
    code: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [buttonClick, setButtonClick] = useState(false)
  const [passwordState, setPasswordState] = useState(false)
  const [buttonClickChange, setButtonClickChange] = useState(false)

  const handleChangePassword = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    setButtonClickChange(false)
    const { value } = event_.target
    if (stateName === 'newPassword' && passwordState && validatePassword(event_.target.value)) {
      setPasswordState(false)
    } else if (
      stateName === 'newPassword' &&
      !validatePassword(event_.target.value) &&
      !passwordState &&
      passwordCheckOpened
    ) {
      setPasswordState(true)
    }
    setFormDataChangePassword((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleFocusValidation = (val: string, key: string, value: string) => {
    if (key === 'newPassword') {
      if (val === 'focus') {
        passwordCheckOpened = true
        if (!validatePassword(value)) {
          setPasswordState(true)
        }
      } else {
        setPasswordState(false)
      }
    }
  }

  const handleChange = (event_: React.ChangeEvent<HTMLInputElement>, stateName: string) => {
    const { value } = event_.target
    for (const error of errorState) {
      if (stateName === 'email' && !emailRegex.test(value.trim())) {
        error.emailError = true
      } else if (emailRegex.test(value.trim())) {
        error.emailError = false
      }
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleClick = async () => {
    if (forgotPasswordStatus !== 'success') {
      setButtonClick(true)

      if (!errorState[0].emailError && formData.email !== '') {
        forgotPasswordAction({
          email: formData.email,
        })
      }
    } else {
      setButtonClickChange(true)
      if (
        validatePassword(formDataChangePassword.newPassword) &&
        formDataChangePassword.confirmPassword === formDataChangePassword.newPassword &&
        formDataChangePassword.newPassword &&
        formData.email
      ) {
        const body = {
          email: formData.email,
          password: formDataChangePassword.newPassword,
          code: formDataChangePassword.code,
        }
        confirmForgotPasswordAction(body)
      }
    }
  }

  useEffect(() => {
    if (forgotPasswordStatus === 'success') {
      setTimeout(() => {
        setRedirectPart(true)
      }, 2000)
    }
  }, [forgotPasswordStatus])
  return (
    <>
      <h1>
        {forgotPasswordStatus === 'success' && redirectPart ? 'Reset Password' : 'Forgot Password'}
      </h1>
      {forgotPasswordStatus === 'success' && redirectPart ? (
        <ChangePasswordFields
          passwordState={passwordState}
          formDataChangePassword={formDataChangePassword}
          handleChangePassword={handleChangePassword}
          handleFocusValidation={handleFocusValidation}
          buttonClick={buttonClickChange}
          confirmPasswordData={confirmPasswordData}
          confirmPasswordStatus={confirmPasswordStatus}
        />
      ) : (
        <>
          <label>Email Address*</label>
          <Input onChange={(e) => handleChange(e, 'email')} placeholder='Enter your email' />
          {errorState[0].emailError || (formData.email === '' && buttonClick) ? (
            <p className='error-message message-active'>*Please enter a valid email address</p>
          ) : (
            <p className='error-message message-desable'></p>
          )}
        </>
      )}
      {forgotPasswordStatus === 'success' && !redirectPart && (
        <p className='check-email check-active'>Please check your email</p>
      )}
      <>
        {forgotPasswordData && !redirectPart && forgotPasswordStatus !== 'pending' && (
          <p className='error-message message-active'>{forgotPasswordData}</p>
        )}
      </>
      <Button onClick={handleClick}>
        {forgotPasswordStatus === 'pending' || confirmPasswordStatus === 'pending' ? (
          <Spinner />
        ) : (
          'Submit'
        )}
      </Button>
    </>
  )
}

export default ForgotPasswordFields
