/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react'
import { ProfileNoPendingInterface } from '../../ProfileFields/interfaces'
import { Checkbox } from 'antd'

const ProfileNoPending: FC<ProfileNoPendingInterface> = ({
  profileResultNoPendingData,
  profileInfoNoPendingData,
}) => {
  // const [questionState, setQuestionState] = useState([
  //   {
  //     text: 'August 2023',
  //     buttonText: 'Sorry, There is no trial available.',
  //     id: '1',
  //     imgPlus: require('../../../../assets/images/subscribe/plus.svg'),
  //     imgMin: require('../../../../assets/images/subscribe/minus.svg'),
  //     active: 'plus',
  //   },
  //   {
  //     text: 'September 2023',
  //     buttonText: 'Sorry, There is no trial available.',
  //     id: '2',
  //     imgPlus: require('../../../../assets/images/subscribe/plus.svg'),
  //     imgMin: require('../../../../assets/images/subscribe/minus.svg'),
  //     active: 'plus',
  //   },
  //   {
  //     text: 'September 2023',
  //     buttonText: 'Sorry, There is no trial available.',
  //     id: '3',
  //     imgPlus: require('../../../../assets/images/subscribe/plus.svg'),
  //     imgMin: require('../../../../assets/images/subscribe/minus.svg'),
  //     active: 'plus',
  //   },
  //   {
  //     text: 'September 2023',
  //     buttonText: 'Sorry, There is no trial available.',
  //     id: '4',
  //     imgPlus: require('../../../../assets/images/subscribe/plus.svg'),
  //     imgMin: require('../../../../assets/images/subscribe/minus.svg'),
  //     active: 'plus',
  //   },
  //   {
  //     text: 'September 2023',
  //     buttonText: 'Sorry, There is no trial available.',
  //     id: '5',
  //     imgPlus: require('../../../../assets/images/subscribe/plus.svg'),
  //     imgMin: require('../../../../assets/images/subscribe/minus.svg'),
  //     active: 'plus',
  //   },
  // ])
  const [activeState, setActiveState] = useState('plus')
  const [dateState, setDateState] = useState('')

  // const handleClick = (id: string, type: string) => {
  //   const newData = [...questionState]
  //   for (const [idx, result] of questionState.entries()) {
  //     if (result.id === id) {
  //       newData[idx].active = type
  //     }
  //   }
  //   setQuestionState(newData)
  // }

  // const date = new Date(dateStr)

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const handleClickActive = (type: string, data: string) => {
    setActiveState(type)
    setDateState(data)
  }

  useEffect(() => {
    if (profileResultNoPendingData && profileResultNoPendingData?.data?.result) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const data of profileResultNoPendingData?.data?.result) {
        setDateState(data[0].website)
      }
    }
  }, [dateState, profileResultNoPendingData?.data?.result])

  return (
    <>
      <div className='container'>
        <div className='profile-status-part'>
          <div className='profile-info-section'>
            <h1>Profile Information</h1>
            <div className='info'>
              <h1>James Smith</h1>
              <div className='info-child'>
                <div>
                  <p>Location:</p>
                  <span>
                    {profileInfoNoPendingData?.data.city} {profileInfoNoPendingData?.data.state}
                  </span>
                </div>
                <div>
                  <p>BirthDate:</p>
                  <span>{profileInfoNoPendingData?.data.birthDate}</span>
                </div>
                <div>
                  <p>Email:</p>
                  <span>{profileInfoNoPendingData?.data.email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='profile-info-section previous-result'>
            {profileResultNoPendingData?.data.result.length ? (
              <>
                <div className='previous-result-search'>
                  <h1>Previous Results</h1>
                  <div className='next-search'>
                    <h2>Next Search in</h2>
                    <h3>20 Days</h3>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {profileResultNoPendingData?.data.result.map((item) => {
              const dateStr = item[0].updated_at
              const date = new Date(dateStr)
              const monthName = monthNames[date.getMonth()]
              const year = date.getFullYear()
              const customData = item[0].website

              const formattedMonth = `${monthName} ${year}`
              return (
                <>
                  <div className='asked-box'>
                    <div className=''>
                      <div className='global-box'>
                        <>
                          <div className='available available-pending'>
                            <div
                              className='available-info'
                              onClick={() =>
                                handleClickActive(
                                  activeState === 'plus' ? 'minus' : 'plus',
                                  customData,
                                )
                              }
                            >
                              <div className='title-section'>
                                <p>{formattedMonth}</p>
                                {activeState === 'plus' ? (
                                  <img
                                    onClick={() => handleClickActive('minus', customData)}
                                    className='vector'
                                    src={
                                      require('../../../../assets/images/subscribe/plus.svg')
                                        .default
                                    }
                                  />
                                ) : (
                                  <img
                                    onClick={() => handleClickActive('plus', customData)}
                                    className='vector'
                                    src={
                                      require('../../../../assets/images/subscribe/minus.svg')
                                        .default
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  {activeState === 'plus' && dateState === item[0].website ? (
                    ''
                  ) : (
                    <>
                      <div className='pending-global-box'>
                        <div className='top-box top-box-pending'>
                          <div className='web-result-section'>
                            <div className='check-section'>
                              <Checkbox className='scales-checkbox' />
                              <p>Website</p>
                            </div>
                            <p>Result</p>
                          </div>
                          <p className='remove-part'>Removal Requested On</p>
                        </div>

                        {item.map(({ details, website }) => {
                          return (
                            <>
                              <div className='pending-content'>
                                {Object.values(details).map((items) => (
                                  <>
                                    {items.map(({ age, address, state, city }) => (
                                      <>
                                        <div className='under-box '>
                                          <div className='result-section'>
                                            <div className='result-table'>
                                              <>
                                                <div className='global-box'>
                                                  <div className='check-state'>
                                                    <Checkbox className='scales-checkbox' />
                                                    <div className='info-state'>
                                                      <img
                                                        className='vector'
                                                        src={
                                                          require('../../../../assets/images/result/logo-main 1.svg')
                                                            .default
                                                        }
                                                      />
                                                      <p>{website}</p>
                                                    </div>
                                                  </div>
                                                  <div className='all-section'>
                                                    <div className='middle-box'>
                                                      <span className='name-state'>
                                                        Steve Martin
                                                      </span>
                                                      <p>
                                                        Age:<span>{age}</span>
                                                      </p>
                                                      <p>
                                                        Location:
                                                        <span>
                                                          {' '}
                                                          {address}, {state}, {city}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    <div className='mobile-part'>
                                                      <p>Removal Requested On</p>
                                                      <div className='button-box'>
                                                        <div className='button-box-part'>
                                                          <p>October 28, 2023</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                ))}
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </>
              )
              // return (
              //   <>
              //     {profileResultNoPendingData?.data.result.map(({ updated_at, details }) => {
              //       const dateStr = updated_at
              //       const date = new Date(dateStr)
              //       const monthName = monthNames[date.getMonth()]
              //       const year = date.getFullYear()

              //       const formattedMonth = `${monthName} ${year}`
              //       return (
              //         <>
              //           <div className='asked-box'>
              //             <div className=''>
              //               <div className='global-box'>
              //                 <>
              //                   <div className='available available-pending'>
              //                     <div className='available-info'>
              //                       <div className='title-section'>
              //                         <p>{formattedMonth}</p>
              //                       </div>
              //                     </div>
              //                   </div>
              //                 </>
              //               </div>
              //             </div>
              //           </div>
              //           <div className='pending-content'>
              //             <div className='top-box top-box-pending'>
              //               <div className='check-section'>
              //                 <Checkbox className='scales-checkbox' />
              //                 <p>Website</p>
              //               </div>
              //               <p>Result</p>
              //             </div>
              //             {Object.values(details).map((items) => (
              //               <>
              //                 {items.map(({ age, address, state, city }) => (
              //                   <>
              //                     <div className='under-box '>
              //                       <div className='result-section'>
              //                         <div className='result-table'>
              //                           <>
              //                             <div className='global-box'>
              //                               <div className='check-state'>
              //                                 <Checkbox className='scales-checkbox' />
              //                                 <div className='info-state'>
              //                                   <img
              //                                     className='vector'
              //                                     src={
              //                                       require('../../../../assets/images/result/logo-main 1.svg')
              //                                         .default
              //                                     }
              //                                   />
              //                                   <p>tester.com</p>
              //                                 </div>
              //                               </div>
              //                               <div className='all-section'>
              //                                 <div className='middle-box'>
              //                                   <span className='name-state'>Steve Martin</span>
              //                                   <p>
              //                                     Age:<span>{age}</span>
              //                                   </p>
              //                                   <p>
              //                                     Location:
              //                                     <span>
              //                                       {' '}
              //                                       {address}, {state}, {city}
              //                                     </span>
              //                                   </p>
              //                                 </div>
              //                                 <div className='button-box'>
              //                                   <Button>October 28, 2023</Button>
              //                                 </div>
              //                               </div>
              //                             </div>
              //                           </>
              //                         </div>
              //                       </div>
              //                     </div>
              //                   </>
              //                 ))}
              //               </>
              //             ))}
              //           </div>
              //         </>
              //       )
              //     })}
              //   </>
              // )
            })}

            {/* {profileResultNoPendingData?.data.result[0].map(({ updated_at, details }) => {
              const dateStr = updated_at
              const date = new Date(dateStr)
              const monthName = monthNames[date.getMonth()]
              const year = date.getFullYear()

              const formattedMonth = `${monthName} ${year}`
              return (
                <>
                  <div className='asked-box'>
                    <div className=''>
                      <div className='global-box'>
                        <>
                          <div className='available available-pending'>
                            <div className='available-info'>
                              <div className='title-section'>
                                <p>{formattedMonth}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className='pending-content'>
                    <div className='top-box top-box-pending'>
                      <div className='check-section'>
                        <Checkbox className='scales-checkbox' />
                        <p>Website</p>
                      </div>
                      <p>Result</p>
                    </div>
                    {Object.values(details).map((items) => (
                      <>
                        {items.map(({ age, address, state, city }) => (
                          <>
                            <div className='under-box '>
                              <div className='result-section'>
                                <div className='result-table'>
                                  <>
                                    <div className='global-box'>
                                      <div className='check-state'>
                                        <Checkbox className='scales-checkbox' />
                                        <div className='info-state'>
                                          <img
                                            className='vector'
                                            src={
                                              require('../../../../assets/images/result/logo-main 1.svg')
                                                .default
                                            }
                                          />
                                          <p>tester.com</p>
                                        </div>
                                      </div>
                                      <div className='all-section'>
                                        <div className='middle-box'>
                                          <span className='name-state'>Steve Martin</span>
                                          <p>
                                            Age:<span>{age}</span>
                                          </p>
                                          <p>
                                            Location:
                                            <span>
                                              {' '}
                                              {address}, {state}, {city}
                                            </span>
                                          </p>
                                        </div>
                                        <div className='button-box'>
                                          <Button>
                                            October 28, 2023
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    ))}
                  </div>
                </>
              )
            })} */}

            {/* 
            <div>

            {questionState.map(({ text, buttonText, imgPlus, imgMin, active, id }) => (
              <>
                <div className='asked-box'>
                  <div className=''>
                    <div className='global-box'>
                      <>
                        <div className='available'>
                          <div className='available-info'>
                            <div className='title-section'>
                              <p>{text}</p>
                              {active === 'plus' ? (
                                <img
                                  onClick={() => handleClick(id, 'minus')}
                                  className='vector'
                                  src={imgPlus.default}
                                />
                              ) : (
                                <img
                                  onClick={() => handleClick(id, 'plus')}
                                  className='vector'
                                  src={imgMin.default}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                {active === 'minus' ? (
                  <div className='under-box '>
                    <div className='result-section'>
                      <div className='result-table'>
                        <div className='top-box'>
                          <div className='check-section'>
                            <Checkbox className='scales-checkbox' />
                            <p>Website</p>
                          </div>
                          <p>Result</p>
                        </div>
                        <>
                          <div className='global-box'>
                            <div className='check-state'>
                              <Checkbox className='scales-checkbox' />
                              <div className='info-state'>
                                <img
                                  className='vector'
                                  src={
                                    require('../../../../assets/images/result/logo-main 1.svg')
                                      .default
                                  }
                                />
                                <p>tester.com</p>
                              </div>
                            </div>
                            <div className='all-section'>
                              <div className='middle-box'>
                                <span className='name-state'>Steve Martin</span>
                                <p>
                                  Age:<span>12</span>
                                </p>
                                <p>
                                  Location:
                                  <span>Los Angeles, CA(Central La), CA, Los Angeles</span>
                                </p>
                              </div>
                              <div className='button-box'>
                                <Button>
                                  October 28, 2023
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className='desable'>{buttonText}</p>
                )}
              </>
            ))}
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileNoPending
