import React, { useState, FC, useRef } from 'react'
import { CheckSectionInterfaces } from './interfaces'
import { Button } from 'antd'
import Spinner from '../../../core-ui/Spinner'

const CheckSection: FC<CheckSectionInterfaces> = ({
  email,
  verifyPhoneAction,
  setCheckBox,
  signUpResetAction,
  password,
  verifyStatus,
  errorMessage,
  resendSignUp,
}) => {
  const [errorBackState, setErrorBackState] = useState(false)

  const [otp, setOTP] = useState<string[]>(new Array(6).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null))

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value.length === 6) {
      const updatedOTP = []
      for (let i = 0; i < value.length; i++) {
        updatedOTP.push(value[i])
      }
      inputRefs.current[5]?.focus()
      return setOTP(updatedOTP)
    }

    if (value.length <= 1) {
      const updatedOTP = [...otp]
      updatedOTP[index] = value
      setOTP(updatedOTP)
      // Move focus to the next input field
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus()
      }

      // If OTP is complete, pass it to the parent component
      const completedOTP = updatedOTP.join('')
      if (!completedOTP.includes('') && completedOTP.length === 6) {
        // onComplete(completedOTP)
      }
    }
  }

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      // Move focus to the previous input field on backspace if the current input is empty
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handleClick = () => {
    if (otp.join('').length === 6) {
      verifyPhoneAction({
        otpCode: otp.join(''),
        email: email,
        password: password,
      })
      setErrorBackState(false)
    }
  }

  const handleClickBack = () => {
    setCheckBox(false)
    signUpResetAction()
  }

  const handleSignUpClick = () => {
    setCheckBox(true)
  }

  const handleResendSignUp = () => {
    const username = email
    resendSignUp(username)
  }

  return (
    <>
      <div className='check-section'>
        <h1>Check you email</h1>
        <p className='sent'>We sent a verification link to</p>
        <p className='email'>
          <span>{email}</span>
        </p>
        <div className='phone-box'>
          {Array.from({ length: 6 }, (_, index) => (
            <input
              className={otp[index] ? 'active' : ''}
              key={index}
              type='number'
              value={otp[index]}
              onChange={(e) => handleInputChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
        </div>
        {errorMessage && verifyStatus !== 'pending' && !errorBackState ? (
          <p className='error-message message-active'>{errorMessage}</p>
        ) : (
          ''
        )}
        <Button onClick={handleClick}>
          {verifyStatus === 'pending' ? <Spinner /> : 'Verify email'}
        </Button>
        <p className='resend'>
          Didn’t receive the email?<span onClick={handleResendSignUp}>Click to resend</span>
        </p>
        <div onClick={handleClickBack} className='back-box'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M15.8332 10H4.1665M4.1665 10L9.99984 15.8334M4.1665 10L9.99984 4.16669'
              stroke='#475467'
              strokeWidth='1.66667'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p onClick={handleSignUpClick}>Back to log in</p>
        </div>
      </div>
    </>
  )
}

export default CheckSection
