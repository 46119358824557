import { HTTP } from '../../utils/http'
import { SEARCH } from '../constants'
import { SearchBody } from './interfaces'

export const Search = (body: SearchBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SEARCH.PENDING })
    const response = await HTTP.post('api/data/search-profile', body)

    dispatch({ type: SEARCH.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: SEARCH.ERROR, payload: error })
  }
}

export const SearchReset = () => ({
  type: SEARCH.RESET,
})
