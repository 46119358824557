import React, { FC, useState, useEffect } from 'react'
import { ResultTableIsLogedinInterface } from './interfaces'
import { resultListReducerData } from '../../store/reducers/interfaces'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ResultTableIsLogedin: FC<ResultTableIsLogedinInterface> = ({
  resultListData,
  // resultRemoveAction,
  // resultRemoveStatus,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // const [isState, setIsState] = useState('')
  const [newDataState, setNewDataState] = useState<resultListReducerData['data']['results']>([])
  useEffect(() => {
    if (resultListData?.data?.results) {
      const newState = [...resultListData.data.results]
      for (const [idx, item] of resultListData.data.results.entries()) {
        for (const data of Object.values(item.details)) {
          if (data.length === 1) {
            newState.push(item)
            newState.splice(idx, 1)
          }
        }
      }
      setNewDataState(newState)
    }
  }, [resultListData?.data?.results])
  // const handleRemoveClick = (url: string) => {
  //   setIsState(url)
  //   const body = {
  //     profileUrl: url,
  //   }
  //   resultRemoveAction(body)
  // }
  return (
    <>
      <div className='container result-section '>
        <div className='result-table result-custom-table result-custom-table result-custom-section'>
          <div className='logo-box'>
            <img src={require('../../assets/images/result/check.svg').default} />
            <h6>James Smith</h6>
          </div>
          <p className='text-found'>
            Found 835 people in <span>California, New Jersey, New York</span> and 47 other states
          </p>
          <div className='top-box'>
            <div className='check-section'>
              <p>Website</p>
            </div>
            <p>Result</p>
          </div>
          <div className='sticky-section'>
            <div className='data-section'>
              {newDataState.map(({ details, website }) => (
                <>
                  {Object.values(details).map((items) => (
                    <>
                      {items.map(({ age, address, state, city, firstName, lastName }) => (
                        <>
                          {age ? (
                            <>
                              <div className='global-box'>
                                <div className='check-state'>
                                  <div className='info-state'>
                                    <img
                                      className='vector'
                                      src={
                                        require('../../assets/images/result/logo-main 1.svg')
                                          .default
                                      }
                                    />
                                    <p>{website}</p>
                                  </div>
                                </div>
                                <div className='all-section'>
                                  <div className='middle-box'>
                                    <span className='name-state'>
                                      {firstName} {lastName}
                                    </span>
                                    <p>
                                      Age:<span>{age}</span>
                                    </p>
                                    <p>
                                      Location:
                                      <span>
                                        {address}, {state}, {city}
                                      </span>
                                    </p>
                                  </div>
                                  {searchParams.get('firstName') === firstName &&
                                  searchParams.get('lastName') === lastName ? (
                                    <div className='extra-match'>
                                      <p>Exact Match</p>
                                    </div>
                                  ) : (
                                    <div className='extra-match parial-match'>
                                      <p>Partial Match</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {items.length === 1 && (
                                <div className='global-box'>
                                  <div className='check-state'>
                                    <div className='info-state'>
                                      <img
                                        className='vector'
                                        src={
                                          require('../../assets/images/result/logo-main 1.svg')
                                            .default
                                        }
                                      />
                                      <p>{website}</p>
                                    </div>
                                  </div>
                                  <div className='all-section global-match-found'>
                                    <div className='match-found'>
                                      <p>No match found</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </div>
            <div className='yourself-section'>
              <div className='yourself-box'>
                <div className='img-section'>
                  <div className='img-box'>
                    <img src={require('../../assets/images/home/Ghost/first.svg').default} />
                  </div>
                  <h1>Ghost Yourself</h1>
                  <div className='img-box-second'>
                    <img src={require('../../assets/images/home/Ghost/second.svg').default} />
                  </div>
                </div>
                <p>
                  Register and we will ensure your disappearance from the listed broker sites Every
                  Month.
                </p>
                <div className=''>
                  <div className='img-box-third'>
                    <img src={require('../../assets/images/home/Ghost/third.svg').default} />
                  </div>
                  <div className='img-box-third-fourth'>
                    <img src={require('../../assets/images/home/Ghost/fourth.svg').default} />
                  </div>
                </div>
                <button
                  onClick={() =>
                    navigate(
                      `/sign-up?firstName=${searchParams.get(
                        'firstName',
                      )}&lastName=${searchParams.get('lastName')}&city=${searchParams.get(
                        'city',
                      )}&state=${searchParams.get('state')}`,
                    )
                  }
                >
                  Register Now
                </button>
              </div>
            </div>
          </div>
          {/* <div className='global-box'>
            <div className='check-state'>
              <Checkbox className='scales-checkbox' />
              <div className='info-state'>
                <img
                  className='vector'
                  src={require('../../assets/images/result/logo-main 1.svg').default}
                />
                <p>www.radaris.com</p>
              </div>
            </div>
            <div className='all-section'>
              <div className='middle-box'>
                <span className='name-state'>James Smith</span>
                <p>
                  Age:<span>50</span>
                </p>
                <p>
                  Location:<span>Marina Del Rey, CA, Los Angeles</span>
                </p>
              </div>
              <div className='button-box'>
                <Button>Remove</Button>
                <Button>Keep</Button>
                <Button>Hide</Button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default ResultTableIsLogedin
