import axios from 'axios'
import { Auth } from 'aws-amplify'

const APPLICATION_JSON = 'application/json'

export const HTTP = {
  async get(path: string) {
    const token = await this.refreshToken()
    const URL = `${process.env.REACT_APP_HOST}/${path}`
    const headers = {
      'Content-Type': APPLICATION_JSON,
      Authorization: token || '',
    } as Record<string, string>
    return axios.get(URL, { headers })
  },

  async patch(path: string, body: any) {
    const URL = `${process.env.REACT_APP_HOST}/${path}`
    const token = await this.refreshToken()
    const headers = {
      'Content-Type': APPLICATION_JSON,
      Authorization: token || '',
    }
    return axios({
      method: 'patch',
      url: URL,
      data: JSON.stringify(body),
      timeout: 40000,
      headers,
    })
  },
  async post(path: string, body: any) {
    const URL = `${process.env.REACT_APP_HOST}/${path}`
    const token = await this.refreshToken()
    const headers = {
      'Content-Type': APPLICATION_JSON,
      Authorization: token || '',
    }
    return axios({
      method: 'post',
      url: URL,
      data: JSON.stringify(body),
      timeout: 40000,
      headers,
    })
  },
  async put(path: string, body: any) {
    const URL = `${process.env.REACT_APP_HOST}/${path}`
    const token = await this.refreshToken()
    const headers = {
      'Content-Type': APPLICATION_JSON,
      Authorization: token || '',
    }
    return axios({
      method: 'put',
      url: URL,
      data: JSON.stringify(body),
      timeout: 40000,
      headers,
    })
  },
  async delete(path: string) {
    const URL = `${process.env.REACT_APP_HOST}/${path}`
    const token = await this.refreshToken()
    const headers = {
      'Content-Type': APPLICATION_JSON,
      Authorization: token || '',
    }
    return axios({ url: URL, method: 'delete', timeout: 40000, headers })
  },
  refreshToken(): Promise<string> {
    return new Promise((resolve) => {
      Auth.currentSession()
        .then((data) => {
          const tokenObj = data.getIdToken()
          resolve(tokenObj.getJwtToken())
        })
        .catch(() => {
          resolve('')
        })
    })
  },
}
