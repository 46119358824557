import {
  SIGN_UP,
  VERIFY_PHONE,
  FORGOT_PASSWORD,
  SIGN_IN,
  CHANGE_PASSWORD,
  RESEND_SIGN_UP,
  CONFIRM_FORGOT_PASSWORD,
} from '../constants'
import { DispatchPayloadType } from './index'

import { AuthReducersInterface } from './interfaces'

const initialState: AuthReducersInterface = {
  signUpStatus: '',
  signUpData: null,

  signInStatus: '',
  signInData: null,

  verifyStatus: '',
  verifyData: null,

  forgotPasswordStatus: '',
  forgotPasswordData: null,

  confirmForgotPasswordStatus: '',

  changePasswordStatus: '',
  changePasswordData: null,

  resendSignUpStatus: '',
  resendSignUpData: null,

  confirmPasswordStatus: '',
  confirmPasswordData: null,
}

const authReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): AuthReducersInterface => {
  switch (type) {
    case SIGN_UP.PENDING: {
      return {
        ...state,
        signUpStatus: 'pending',
      } as AuthReducersInterface
    }
    case SIGN_UP.SUCCESS: {
      return {
        ...state,
        signUpStatus: 'success',
        signUpData: payload,
      } as AuthReducersInterface
    }
    case SIGN_UP.ERROR: {
      return {
        ...state,
        signUpStatus: 'error',
        signUpData: error,
      } as AuthReducersInterface
    }
    case SIGN_UP.RESET: {
      return {
        ...state,
        signUpStatus: '',
      } as AuthReducersInterface
    }
    case VERIFY_PHONE.PENDING: {
      return {
        ...state,
        verifyStatus: 'pending',
      } as AuthReducersInterface
    }
    case VERIFY_PHONE.SUCCESS: {
      return {
        ...state,
        verifyStatus: 'success',
        verifyData: payload,
      } as AuthReducersInterface
    }
    case VERIFY_PHONE.ERROR: {
      return {
        ...state,
        verifyStatus: 'error',
        verifyData: error,
      } as AuthReducersInterface
    }
    case VERIFY_PHONE.RESET: {
      return {
        ...state,
        verifyStatus: '',
      } as AuthReducersInterface
    }

    case SIGN_IN.PENDING: {
      return {
        ...state,
        signInStatus: 'pending',
      } as AuthReducersInterface
    }
    case SIGN_IN.SUCCESS: {
      return {
        ...state,
        signInStatus: 'success',
        signInData: payload,
      } as AuthReducersInterface
    }
    case SIGN_IN.ERROR: {
      return {
        ...state,
        signInStatus: 'error',
        signInData: error,
      } as AuthReducersInterface
    }
    case SIGN_IN.RESET: {
      return {
        ...state,
        signInStatus: '',
      } as AuthReducersInterface
    }

    case FORGOT_PASSWORD.PENDING: {
      return {
        ...state,
        forgotPasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        forgotPasswordStatus: 'success',
        forgotPasswordData: payload,
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        forgotPasswordStatus: 'error',
        forgotPasswordData: error,
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.RESET: {
      return {
        ...state,
        forgotPasswordStatus: '',
        forgotPasswordData: null,
      } as AuthReducersInterface
    }

    case CHANGE_PASSWORD.PENDING: {
      return {
        ...state,
        changePasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case CHANGE_PASSWORD.SUCCESS: {
      return {
        ...state,
        changePasswordStatus: 'success',
        changePasswordData: payload,
      } as AuthReducersInterface
    }
    case CHANGE_PASSWORD.ERROR: {
      return {
        ...state,
        changePasswordStatus: 'error',
        changePasswordData: error,
      } as AuthReducersInterface
    }
    case CHANGE_PASSWORD.RESET: {
      return {
        ...state,
        changePasswordStatus: '',
        changePasswordData: null,
      } as AuthReducersInterface
    }

    case RESEND_SIGN_UP.PENDING: {
      return {
        ...state,
        resendSignUpStatus: 'pending',
      } as AuthReducersInterface
    }
    case RESEND_SIGN_UP.SUCCESS: {
      return {
        ...state,
        resendSignUpStatus: 'success',
        resendSignUpData: payload,
      } as AuthReducersInterface
    }
    case RESEND_SIGN_UP.ERROR: {
      return {
        ...state,
        resendSignUpStatus: 'error',
        resendSignUpData: error,
      } as AuthReducersInterface
    }
    case RESEND_SIGN_UP.RESET: {
      return {
        ...state,
        resendSignUpStatus: '',
      } as AuthReducersInterface
    }

    case CONFIRM_FORGOT_PASSWORD.PENDING: {
      return {
        ...state,
        confirmPasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        confirmPasswordStatus: 'success',
        confirmPasswordData: payload,
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        confirmPasswordStatus: 'error',
        confirmPasswordData: error,
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.RESET: {
      return {
        ...state,
        confirmPasswordStatus: '',
        confirmPasswordData: null,
      } as AuthReducersInterface
    }

    default:
      return state
  }
}

export default authReducer
