import React from 'react'

const Search = () => {
  return (
    <div className='search-container'>
      <div className='container'>
        <h1>
          Your search is <span>Anonymous</span> and <span>Private</span>
        </h1>
        <p>We never disclose your search, unless required by law.</p>
      </div>
    </div>
  )
}

export default Search
