/* eslint-disable camelcase */
import React, { FC, useState } from 'react'
import { Button, Input, Select, DatePicker, DatePickerProps } from 'antd'
import state from '../../../../assets/state'
import { ProfileEditSectionInterface } from '../ProfileView/interface'
import Spinner from '../../../../core-ui/Spinner'
import { emailRegex } from '../../../../utils/regex'
import dayjs from 'dayjs'

const ProfileEditFields: FC<ProfileEditSectionInterface> = ({
  profileEditInfoAction,
  profileEditStatus,
  profileEditData,
  profileInfoNoPendingData,
  setEditProfileState,
}) => {
  const { Option } = Select
  const [emailError, setEmailError] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [selectState, setSelectState] = useState(profileInfoNoPendingData?.data.state || '')
  const [dateOfBirth, setDateOfBirth] = useState(profileInfoNoPendingData?.data.birthDate || '')
  const [formData, setFormData] = useState({
    firstName: profileInfoNoPendingData?.data.firstName || '',
    lastName: profileInfoNoPendingData?.data.lastName || '',
    city: profileInfoNoPendingData?.data.city || '',
    email: profileInfoNoPendingData?.data.email || '',
  })

  const handleChange = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    const { value } = event_.target
    if (stateName === 'email' && !emailRegex.test(value.trim())) {
      setEmailError(true)
    } else if (emailRegex.test(value.trim())) {
      setEmailError(false)
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleChangeSelect = (event: string) => {
    setSelectState(event)
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setDateOfBirth(dateString)
  }

  const handleClick = () => {
    setButtonClick(true)
    if (formData.city && formData.email && formData.firstName && dateOfBirth && selectState) {
      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        birth_date: dateOfBirth,
        city: formData.city,
        state: selectState,
      }
      profileEditInfoAction(body)
    }
  }

  return (
    <>
      <div className='profile-edit'>
        <div className='colum-fileds'>
          <div>
            <label>First name*</label>
            <Input
              defaultValue={profileInfoNoPendingData?.data.firstName}
              className='input-box'
              onChange={(e) => handleChange(e, 'firstName')}
              placeholder='First name'
            />
            {formData.firstName === '' && buttonClick ? (
              <p className='error-message message-active'>*Please enter a valid firstName</p>
            ) : (
              <p className='error-message message-desable'>*Please enter a valid firstName</p>
            )}
          </div>
          <div>
            <label>Last name*</label>
            <Input
              className='input-box'
              onChange={(e) => handleChange(e, 'lastName')}
              placeholder='Last name'
              defaultValue={profileInfoNoPendingData?.data.lastName}
            />
            {formData.lastName === '' && buttonClick ? (
              <p className='error-message message-active'>*Please enter a valid lastName</p>
            ) : (
              <p className='error-message message-desable'>*Please enter a valid lastName</p>
            )}
          </div>
          <div>
            <label>Email Address*</label>
            <Input
              disabled
              className='input-box'
              onChange={(e) => handleChange(e, 'email')}
              placeholder='Enter your email'
              defaultValue={profileInfoNoPendingData?.data.email}
            />
            {(formData.email === '' || emailError) && buttonClick ? (
              <p className='error-message message-active'>*Please enter a valid email address</p>
            ) : (
              <p className='error-message message-desable'>*Please enter a valid email address</p>
            )}
          </div>
        </div>
        <div className='colum-fileds'>
          <div>
            <label>Birth Date*</label>
            <DatePicker
              defaultValue={dayjs(profileInfoNoPendingData?.data.birthDate)}
              onChange={onChangeDate}
            />

            {dateOfBirth === '' && buttonClick ? (
              <p className='error-message message-active'>*Please enter a valid dateOfBirth</p>
            ) : (
              <p className='error-message message-desable'>*Please enter a valid dateOfBirth</p>
            )}
          </div>
          <div>
            <label>City*</label>
            <Input
              className='input-box'
              onChange={(e) => handleChange(e, 'city')}
              placeholder='City'
              defaultValue={profileInfoNoPendingData?.data.city}
            />
            {formData.city === '' && buttonClick ? (
              <p className='error-message message-active'>*Please enter a valid city</p>
            ) : (
              <p className='error-message message-desable'>*Please enter a valid city</p>
            )}
          </div>
          <div>
            <label>State*</label>
            <Select
              defaultValue={profileInfoNoPendingData?.data.state}
              suffixIcon={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='21'
                  height='21'
                  viewBox='0 0 21 21'
                  fill='none'
                >
                  <path
                    d='M5.93945 7.51904L10.9395 12.519L15.9395 7.51904'
                    stroke='#667085'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              }
              onChange={(e) => handleChangeSelect(e)}
            >
              {state.map(({ name }) => (
                <>
                  <Option value={name}>{name}</Option>
                </>
              ))}
            </Select>{' '}
            {selectState === '' && buttonClick ? (
              <p className='error-message message-active'>*Please enter a valid state</p>
            ) : (
              <p className='error-message message-desable'>*Please enter a valid state</p>
            )}
          </div>
        </div>
        {profileEditData && buttonClick && profileEditStatus !== 'pending' ? (
          <p className='error-message message-active'>{profileEditData}</p>
        ) : (
          <p className='error-message message-desable'>{profileEditData}</p>
        )}
        <div className='button-box-bottom'>
          <div className='button-box second-btn'>
            <Button onClick={() => setEditProfileState(false)}>Cancel</Button>
          </div>
          <div onClick={handleClick} className='button-box'>
            <Button>{profileEditStatus === 'pending' ? <Spinner /> : 'Save'}</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileEditFields
