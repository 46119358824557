import React, { FC } from 'react'
import './styles.scss'
import { Elements } from '@stripe/react-stripe-js'
import Spinner from '../../core-ui/Spinner'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import { PaymentInterface } from './interfaces'
const stripePromise = loadStripe('pk_test_7z3TSi85l1AKUoeAAHHvaj5w') // Replace with your actual publishable key

const PaymentPlan: FC<PaymentInterface> = ({ stepState, subscibeAction, subscribeStatus }) => {
  return (
    <>
      <div className='container'>
        <div className='payment-section'>
          <h1>Payment Method</h1>
        </div>
        {stripePromise ? (
          <Elements
            stripe={stripePromise}
            options={
              {
                // clientSecret: clientSecret,
              }
            }
          >
            <CheckoutForm
              subscribeStatus={subscribeStatus}
              stepState={stepState}
              subscibeAction={subscibeAction}
            />
            {/* <StripePayment /> */}
          </Elements>
        ) : (
          <div className='stripe-custom-loader'>
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}

export default PaymentPlan
